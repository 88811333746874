import React, { Component } from "react";
import "../Home.css";
import { Link } from "react-router-dom";
import {
  RemoveRedEye,
  EditNote,
  Collections,
  Storage,
  DataObject,
} from "@mui/icons-material";
import axios from "axios";
 
import Tooltip from "@mui/material/Tooltip";
import Parse from "parse";
import {
  getCurrentTenant,
  getExpiryPlans,
  getHasplanPurchase,
  setHasplanPurchase,
  setExpiryPlans,
  getUserRole,
} from "../utils/util";
import MaterialTable from "@material-table/core";
import Loading from "./Loading";
 
export class Home extends Component {
  constructor() {
    super();
    this.state = this.initialState;
  }
  initialState = {
    transactions: [], //transactions state typearray
    IncludedApps: [], //included individual app array
    loading: true, //initially loading is false
    subUsersCount: 0, //subusercount initially zero
    IncludedAppsCount: 0, //included individual app count initially zero.
    daysLeft1: 0, //days left initially 0
    application: [], //app array
    isPlanExpired: false, //plan not expired
    isPlanPerchased: true, //plan purchase
    UserRole: ["admin", "manager", "accounts"],
    dbStats: [],
    seeMore: false,
    orgName: "",
    serverUrl: "",
    appId: "",
     
  };
  //for getting current subscription
  currentsubscriptionsPlansDetails = async () => {
    const Tenant = Parse.Object.extend("partners_Tenant"), //
      tenantResult = getCurrentTenant(), //get current tenant result.
      tenantPointer = Tenant.createWithoutData(tenantResult.objectId), //create pointer by using createWithoutData methods.
      Subscriptions = Parse.Object.extend("partners_Subscriptions"),
      querySubscriptions = new Parse.Query(Subscriptions);
    querySubscriptions.include("PlansPtr");
    querySubscriptions.equalTo("TenantId", tenantPointer);
    querySubscriptions.equalTo("TxnStatus", "Success");
    querySubscriptions.notEqualTo("IsDeleted", true);
    const subscriptionsResult = await querySubscriptions.find();

    //check proper validation ,if subscriptions then go inside the loop
    if (subscriptionsResult.length > 0) {
      setHasplanPurchase(true); //plan Purchase.
      //filter transactions using today date is greter than last date .
      const filtertrnasactions = subscriptionsResult.filter((tr) => {
          return (
            new Date().getTime() <=
            new Date(`${tr.attributes?.ToDate}`).getTime()
          );
        }),
        //sort transactions using updated plans and find days left
        sortedTransactions = filtertrnasactions.sort(
          (a, b) => b.createdAt - a.createdAt //it take always last dated plan
        ),
        // a = moment(sortedTransactions[0].attributes.ToDate),
        // b = moment(sortedTransactions[0].attributes.FromDate),
        // daysLeft = a.diff(b, "days");
        oneDay = 24 * 60 * 60 * 1000;
      const daysLeft1 =
        (sortedTransactions[0] &&
          sortedTransactions[0].attributes.ToDate - new Date()) / oneDay;

      //check proper validation daysleft is always greter than zero.

      const userrole = getUserRole();
      if (daysLeft1 > 0) {
        setExpiryPlans(false); //plan is not expired

        this.subuserscountDetails();
        if (userrole !== "accounts") {
          this.checkdbStatus();
          this.applicationsDetails();
        } else {
          this.setState({ loading: false });
        }
      } else {
        setExpiryPlans(true); //plan is  expired

        this.subuserscountDetails();
        if (userrole !== "accounts") {
          this.checkdbStatus();

          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
        }
      }
      this.setState({
        transactions: [sortedTransactions[0]], //update transactions
        daysLeft1: daysLeft1, //update days left
      });
    } else {
      this.subuserscountDetails();
      this.setState({ loading: false });
      setHasplanPurchase(false); //plan is not purchase.
      setExpiryPlans(true); //plan expired.
    }
    const getexpiriPlan = getExpiryPlans(), //define getExpiryPlans
      hasPlanPurchased = getHasplanPurchase(); //define getHasplanPurchase
    this.setState({
      isPlanExpired: getexpiriPlan, //update isPlanExpired
      isPlanPerchased: hasPlanPurchased, //update isPlanPerchased
      // loading: false,
    });
  };
  //function for getting installed application details
  applicationsDetails = async () => {
    const tenantResult = getCurrentTenant();

    const adminID = tenantResult.UserId.objectId;
    const orgQuery = new Parse.Query("orgApps");

    orgQuery.equalTo("superAdmin", {
      __type: "Pointer",
      className: "_User",
      objectId: adminID,
    });

    const res = await orgQuery.first();

    if (res) {
      const result = JSON.parse(JSON.stringify(res));
      this.setState({
        orgName: result.appName,
        serverUrl: result.publicServerURL,
        appId: result.appId,
      });
      

      try {
        // const checkApp = await Parse.Cloud.run("checkInstalledApp", {
        //   orgName: result.appName,
        // });
        const parseAppId = "aprjnnhfcp";
        const serverUrl =
          "https://root.qik.ai/app/functions/checkInstalledApp";
        const data = {
          orgName: result.appName,
        };
      const installedAppRes =  await axios
          .post(`${serverUrl}`, data, {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": parseAppId,
              sessiontoken :JSON.parse(localStorage.getItem("auth_token"))
            },
          })
  const checkApp = installedAppRes.data.result;
        //  console.log("checkapp",checkApp,)
        let count, application;
        if (checkApp.count > 0 && checkApp.appList) {
          count = checkApp.count;
          application = JSON.parse(checkApp.appList);
          this.setState({
            application: application, //update applications
            loading: false,
            appCount: count,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (err) {
        if (err) {
          console.log("Errr ", err);
        }
      }
    }
  };

  //function for subuser count details
  subuserscountDetails = async () => {
    const Tenant = Parse.Object.extend("partners_Tenant"),
      tenantResult = getCurrentTenant(),
      tenantPointer = Tenant.createWithoutData(tenantResult.objectId),
      Subusers = Parse.Object.extend("partners_SubUsers"),
      query = new Parse.Query(Subusers);
    query.equalTo("TenantId", tenantPointer);
    const result = await query.count();
    if (result) {
      this.setState({ subUsersCount: result });
    }
  };
  //this function for fetching collections and storage data from base db
  checkdbStatus = async () => {
    try {
      const tenantResult = getCurrentTenant();

      const adminID = tenantResult.UserId.objectId;
      const orgQuery = new Parse.Query("orgApps");

      orgQuery.equalTo("superAdmin", {
        __type: "Pointer",
        className: "_User",
        objectId: adminID,
      });

      const res = await orgQuery.first();

      if (res) {
        const result = JSON.parse(JSON.stringify(res));

        // const appRes = await Parse.Cloud.run("checkDBStats", {
        //   appId: result.appId,
        // });
        const parseAppId = "aprjnnhfcp";
        const serverUrl =
          "https://root.qik.ai/app/functions/checkDBStats";
        const data = {
          appId: result.appId,
        };
      const dbStatsRes =  await axios
          .post(`${serverUrl}`, data, {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": parseAppId,
              sessiontoken :JSON.parse(localStorage.getItem("auth_token"))
            },
          })

      const appRes = dbStatsRes.data.result;
 

        if (appRes) {
          this.setState({ dbStats: [appRes] });
        }
      }
    } catch (e) {
      console.log("orgError:", e);
    }
  };
  componentDidMount() {
    //Runs after the first render() lifecycle

    this.currentsubscriptionsPlansDetails();

    const userrole = getUserRole();

    this.setState({
      UserRole: userrole,
    });
  }

  render() {
    const {
      application, //applications state
      transactions, //transactions state
      loading, //loading state
      subUsersCount, //subuser count state
      appCount, //appcount state
      isPlanExpired, //plan expired state
      isPlanPerchased, //plan purchase state
      daysLeft1,
      UserRole,
      dbStats,
      seeMore,
      serverUrl,
      appId,
      orgName,
     
    } = this.state; //extract all state

    // Use data to specify the data that you want to display on table.
    const data = transactions.map((trns) => {
      return {
        packageName: (
          <div>
            <button type="button" className="btn btn-circle"></button>
            <span style={{ marginLeft: 3 }}>
              {trns.attributes.PlansPtr.attributes.PlanName}
            </span>
          </div>
        ),

        transDate: trns.attributes.TxnDate.toDateString(),
        status: trns.get("TxnStatus") === "Success" ? "Successful" : "Failed",
      };
    });

    const daysLeftUI = (
      <>
        <b className="uvsbwhite">{parseInt(daysLeft1)}</b>
        {/* <img className="uvsbwhiteimg" alt="uvsbwhiteimg" /> */}
      </>
    );
    const changeInMb = (dataSize) => {
      const MB = dataSize * 0.000001;
      const mbData = MB.toFixed(5);
      return mbData;
    };
    const option = {
      toolbar: false,
      showTitle: false,
      search: false,
      paging: false,
      headerStyle: {
        border: "1px solid #e5e5e5",
        width: "20rem",
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        //  textAlign: "center",
        //alignItem:"center",
        backgroundColor: "#edebeb",
      },
      rowStyle: {
        alignItem: "center",
        textAlign: "center",
        //fontFamily: "serif",
        color: "black",
        borderRight: "1px solid #e5e5e5",
      },
    };
    const TableCellStyle = { borderRight: "1px solid #e5e5e5" };
    const columns = [
      {
        field: "packageName",
        title: "Package",
        cellStyle: TableCellStyle,
      },

      {
        field: "transDate",
        title: "Transaction",
        cellStyle: TableCellStyle,
      },

      {
        field: "status",
        title: "Status",
        cellStyle: TableCellStyle,
      },
    ];

    const applicationData =
      seeMore && application.length > 0 ? application : application.slice(0, 2);

  
    return (
      <>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between">
                <h1 className="h3 text-uvsh">Dashboard - Qik Admin Console</h1>
              </div>
              <div className="card-columns">
                {UserRole !== "accounts" && (
                  <div
                    className="card  bg-danger"
                    style={{ borderRadius: 0, borderWidth: 0 }}
                  >
                    <div className="card-body d-flex flex-direction-row ">
                      <div>
                        <img src="img/d1.svg" alt="d1" />
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <h6 style={{ color: "white" }}>Sub-users Added</h6>

                        <div className="small">
                          <p style={{ color: "white" }}>
                            <b className="uvsbwhite">{subUsersCount}</b>
                            {/* <img className="uvsbwhiteimg" alt="img" /> */}
                            Console users added
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {UserRole !== "accounts" && (
                  <div
                    className="card  bg-warning"
                    style={{ borderRadius: 0, borderWidth: 0 }}
                  >
                    <div className="card-body d-flex flex-direction-row ">
                      <div>
                        <img src="img/d2.svg" alt="d2" />
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <h6 style={{ color: "white" }}> Applications</h6>
                        <div className="small">
                          {isPlanPerchased ? (
                            appCount ? (
                              <>
                                <p style={{ color: "white" }}>
                                  <b className="uvsbwhite">{appCount} </b>
                                  {/* <img className="uvsbwhiteimg" alt="img2" /> */}
                                  new app(s) installed by you
                                </p>
                              </>
                            ) : (
                              <p style={{ color: "white" }}>
                                0 app(s) installed
                              </p>
                            )
                          ) : (
                            <span className="no-data-dashboard">
                              <Link className="linktext" to="/MyApplication">
                                <p>N/A. Renew now</p>
                              </Link>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="card  bg-primary"
                  style={{ borderRadius: 0, borderWidth: 0 }}
                >
                  <div className="card-body d-flex flex-direction-row ">
                    <div>
                      <img src="img/d3.svg" alt="cardbody" />
                    </div>
                    <div style={{ marginLeft: 20 }}>
                      <h6 style={{ color: "white" }}>Days to Renewal </h6>
                      <div className="small">
                        {isPlanPerchased ? (
                          isPlanExpired ? (
                            <p style={{ color: "white", fontWeight: "bold" }}>
                              Plan Expired
                            </p>
                          ) : (
                            <p>{daysLeftUI}</p>
                          )
                        ) : (
                          <span className="no-data-dashboard">
                            <Link className="linktext" to="/Subscriptionplan">
                              <p>N/A. Renew now</p>
                            </Link>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {UserRole !== "accounts" && (
                <div className="card-columns">
                  <div
                    className="card"
                    style={{
                      borderRadius: 0,
                      borderWidth: 0,
                      backgroundColor: "#6a3170",
                    }}
                  >
                    <div className="card-body d-flex flex-direction-row ">
                      <div>
                        <Collections color="white" />
                        {/* <img src="img/d3.svg" alt="cardbody" /> */}
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <h6 style={{ color: "white" }}>
                          {" "}
                          Collections -{" "}
                          <span
                            style={{
                              color: "white",
                              fontSize: 17,
                              fontWeight: "bold",
                            }}
                          >
                            {dbStats.length > 0 ? dbStats[0].Collections : "00"}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card"
                    style={{
                      borderRadius: 0,
                      borderWidth: 0,
                      backgroundColor: "#b3853d",
                    }}
                  >
                    <div className="card-body d-flex flex-direction-row ">
                      <div>
                        <Storage color="white" />
                        {/* <img src="img/d3.svg" alt="cardbody" /> */}
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <h6 style={{ color: "white" }}>
                          Database size -{" "}
                          <span
                            style={{
                              color: "white",
                              fontSize: 17,
                              fontWeight: "bold",
                            }}
                          >
                            {dbStats.length > 0
                              ? changeInMb(dbStats[0].DataSize)
                              : "00"}
                          </span>
                          <span
                            style={{
                              color: "white",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            MB
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card"
                    style={{
                      borderRadius: 0,
                      borderWidth: 0,
                      backgroundColor: "#c46437",
                    }}
                  >
                    <div className="card-body d-flex flex-direction-row ">
                      <div>
                        <DataObject color="muted" />
                        {/* <img src="img/d3.svg" alt="cardbody" /> */}
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <h6 style={{ color: "white" }}>
                          {" "}
                          Objects -{" "}
                          <span
                            style={{
                              color: "white",
                              fontSize: 17,
                              fontWeight: "bold",
                            }}
                          >
                            {dbStats.length > 0 ? dbStats[0].Objects : "00"}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                {UserRole !== "manager" && (
                  <div className="col-lg-6 mb-4">
                    <br></br>

                    <h1 className="h3 text-uvsh">Recent Transactions</h1>

                    <div className="card shadow mb-4">
                      <MaterialTable
                        columns={columns}
                        data={data}
                        options={option}
                      />
                      <div style={{ margin: 10 }}>
                        <Link to="/Transactions">
                          {transactions.length > 0
                            ? "See More"
                            : "No Transactions "}
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {UserRole !== "accounts" && (
                  <div className="col-lg-6 mb-4">
                    <br></br>

                    <h1 className="h3 text-uvsh myapp">My Applications</h1>

                    {/* <div className="card shadow appcard mb-4"> */}
                    <div className="card-body text-center">
                      <div className="column uvsrow">
                        {application &&
                          applicationData?.map((app, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                }}
                              >
                                <img
                                  src={app.applogo}
                                  alt={app.appTitle}
                                  style={{
                                    objectFit: "contain",
                                    width: "85px",
                                    height: "85px",
                                    borderRadius: 5,
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                  }}
                                />
                                <p className="appname">{app.appTitle}</p>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                  }}
                                >
                                   
                                  <Tooltip title="Access Now" placement="top-start">
                                 
                                      <a
                                        href={`https://${app.appname}-${orgName}.qik.ai`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        // className="btn btn-primary btn-sm"
                                        style={{ marginBottom: 10 }}
                                       
                                      >
                                        <RemoveRedEye
                                          color="primary"
                                          fontSize="large"
                                        />
                                      </a>
                                  
                                  </Tooltip>

                                  <Tooltip title="Edit">
                                    
                                    <a
                                    href={`https://appcreator.qik.ai/login?serverUrl=${serverUrl}&appId=${appId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                   
                                  >
                                    <EditNote
                                      color="success"
                                      fontSize="large"
                                    />
                                  </a>
                                    
                                  </Tooltip>
 
                                  
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {application.length > 2 ? (
                        seeMore ? (
                          <button
                            className="btn btn-primary btn-sm"
                            style={{ marginTop: 10 }}
                            onClick={() => this.setState({ seeMore: false })}
                          >
                            Show less
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-sm"
                            style={{ marginTop: 10 }}
                            onClick={() => this.setState({ seeMore: true })}
                          >
                            Show More
                          </button>
                        )
                      ) : (
                        application.length <= 0 && (
                          <>
                            <br />
                            <div
                              className="card shadow appcard mb-4"
                              style={{ justifyContent: "center" }}
                            >
                              <Link
                                className="linkapp-contional"
                                to="/MyApplication"
                              >
                                No Applications Installed!
                              </Link>
                            </div>
                          </>
                        )
                      )}
                    </div>
                    {/* </div> */}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
