import React, { Component } from "react";
import "../Register.css";
import "../Snackbar.css";
import Parse from "parse";
import {
  emailValidator,
  passwordValidator,
  phoneValidator,
} from "../utils/validator";
import { Link } from "react-router-dom";
import { registerTenant } from "../utils/util";
import { history } from "../services/history";
export class Signup extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    password: "",
    orgName: "",
    loadingProgress: false,
    termCheck: false,
  };
  //function for register user
  signupUser = async (e) => {
    e.preventDefault();
    const { name, phone, email, password, orgName } = this.state;
    try {
      if (!(name.trim().length > 0)) {
        this.setState({ nameError: "Name should not be a blank" });
      } else if (name.trim().length > 50) {
        this.setState({
          nameError: "Name should be  less than 50 character",
        });
      } else if (!phoneValidator.test(phone)) {
        this.setState({
          mobError: "Mobile No. should be 10 digit",
          nameError: "",
        });
      } else if (!phone.match(/^(?=.*\d)(?=.*[1-9]).{1,10}$/)) {
        this.setState({
          mobError: "Should not accept all zeros",
          nameError: "",
        });
      } else if (!emailValidator.test(email)) {
        this.setState({
          emailError: "Please enter valid email!",
          mobError: "",
        });
      } else if (!passwordValidator.test(password)) {
        this.setState({
          passError:
            "Password Must contain Uppercase letter['A-Z'],Lowercase letter['a-z'],digits['0-9'] and symbols['$@*&%#']",
          emailError: "",
        });
      } else if (!(orgName.trim().length > 0)) {
        this.setState({ orgError: "Organization name should not be a blank" });
      } else if (orgName.trim().length > 20) {
        this.setState({
          orgError: "Orgnization should be  less than 20 character",
          passError: "",
        });
      } else if (!this.state.termCheck) {
        this.setState({
          termsError: "Please accept terms and conditions first",
          orgError: "",
        });
      } else {
        this.setState({
          nameError: "",
          mobError: "",
          emailError: "",
          passError: "",
          loadingProgress: true,
          termsError: "", //terms and conditions check state
          orgName: "",
        });
        //function for registered new  parse user
        const user = new Parse.User();
        this.myFunction("User Account created Successfully");
        user.set("name", name); //set user name
        user.set("username", email);
        user.set("phone", phone); //set phone number
        user.set("email", email); //set email
        user.set("password", password); //set password
        const userDetails = await user.signUp(); //register using signUp methods
        // if userDetails then come in loop

        if (userDetails) {
          try {
            const orgQuery = new Parse.Object("orgApps");

            orgQuery.set("appName", orgName);
            orgQuery.set("superAdmin", userDetails);
            await orgQuery.save();
            console.log("set data");
          } catch (e) {
            console.log("orgError:", e);
          }
          registerTenant(userDetails, false, orgName);

          this.setState({ loadingProgress: false });
          history.push("/login");
          //get register details after go into the login page
        }
      }
    } catch (error) {
      this.myFunction(error.message);
      this.setState({ passError: error.message, loadingProgress: false });
    }
  };
  handleChange = (event) => {
    this.setState({
      termCheck: !this.state.termCheck,
    });
  };
  changeHandler = (event) => {
    const name = event.target.getAttribute("id");
    const value = event.target.value;
   
    if (value) {
      this.setState({
        [name]: value,
      });
    }
  };
  changeHandlerEmail = (event) => {
    const name = event.target.getAttribute("id");
    const value = event.target.value;
    const email = value.toLowerCase();
   // const emaill = emailValidator.test(email);
  //  console.log("email",emaill,email)
    if (email) {
      this.setState({
        [name]: email,
      });
    }
  };
  myFunction = (msg) => {
    var x = document.getElementById("snackbar");
    x.className = "show";
    x.innerHTML = msg;
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  render() {
    const { loadingProgress, email } = this.state;
    return (
      <div style={{ overflow: "hidden" }}>
        <div id="snackbar"></div>

        <div className="row">
          <div className="col-md-5">
            <div className="uvsround">
              <img src="img/logo.svg" alt="logo" />
              <h3>
                <br />
                Welcome to Qik Admin Console
              </h3>
              <p>
                <b>Register to account.</b>
                <br />
              </p>
              <div>
                <span className="asterics">*</span> Name
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  onChange={this.changeHandler}
                />
                <div className="error-div">{this.state.nameError}</div>
                <div style={{ position: "relative" }}>
                  <span className="asterics">*</span> Mobile Number
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="form-control"
                    onChange={this.changeHandler}
                  />
                  <div className="error-div">{this.state.mobError}</div>
                </div>
                <span className="asterics">*</span> Email Address
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  value={email}
                  onChange={this.changeHandlerEmail}
                />
                <div className="error-div">{this.state.emailError}</div>
                <span className="asterics">*</span> Password
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  onChange={this.changeHandler}
                />
                <div className="error-div">{this.state.passError}</div>
                <span className="asterics">*</span> Organization Name
                <input
                  type="text"
                  id="orgName"
                  name="orgName"
                  className="form-control"
                  onChange={this.changeHandler}
                />
                <div className="error-div">{this.state.orgError}</div>
                <input type="checkbox" onChange={this.handleChange} /> I agree
                to <Link to="/Terms"> Terms </Link>,
                <Link to="/Terms"> Policy </Link> and
                <Link to="/Terms"> Condition </Link>
                <div className="error-div">{this.state.termsError}</div>
                <br />
                <br />
                <button
                  className="btn btn-primary btn-block"
                  onClick={(e) => this.signupUser(e)}
                >
                  Register &nbsp;
                  {loadingProgress && <i className="fa fa-spinner fa-spin"></i>}
                </button>
              </div>
              <br /> Already have an account? <Link to="login">Login Now</Link>
            </div>
          </div>
          <div className="col-md-7">
            <div
              className="uvsroundbg"
              style={{
                background: "url(img/bg.png)",
                backgroundRepeat: "round",
              }}
            >
              <img src="img/login.png" alt="login" className="uvsimg" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
