import React from "react";
import ReactLoading from "react-loading";
export default class Loading extends React.Component {
  render() {
    //component for loader
    //  LoadingType = "blank" | "balls" | "bars" | "bubbles" | "cubes" | "cylon" | "spin" | "spinningBubbles" | "spokes";

    return <ReactLoading type={this.props.type || "bars"} color={"#007bff"} />;
  }
}
