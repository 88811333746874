import React, { Component } from "react"; //use here class full component
import { Link } from "react-router-dom"; //use for linking purpose
import Parse from "parse"; //connecting to parse server
import { Navigation } from "./components/Navigation";
import { getHasplanPurchase, isAuth } from "./utils/util";
import { Nav } from "./components/Nav";
import "./App.css";
import { getExpiryPlans } from "./utils/util";

Parse.serverURL =
 "https://root.qik.ai/app"
//  "https://dolphin-app-7d2xp.ondigitalocean.app/app"; //use parse server url
Parse.initialize("aprjnnhfcp"); //initialize parse  appid
localStorage.setItem("appId", JSON.stringify("aprjnnhfcp")); //use same appid in multiple component purpose
//main app component

export class App extends Component {
  constructor() {
    super();
    this.state = this.initialState;
  }
  initialState = {
    isPlanExpired: true, //initially plan expired is true
    isPlanPerchased: false, //initially plan purchase is false.
  };
  //function for current tenant details
  plnasFlagsDetails = async () => {
    const isPlanExpired = getExpiryPlans(), // define getExpiryPlans
      isPlanPerchased = getHasplanPurchase(); //define getHasplanPurchase
    this.setState({
      isPlanExpired: isPlanPerchased && isPlanExpired, //check both conditionally and update the state
      isPlanPerchased: isPlanPerchased, //update isPlanPerchased state.
    });
  };

  componentDidMount() {
    this.plnasFlagsDetails();
  }

  render() {
    const isAuthenticated = isAuth(); //use for proper user authentication
    const { isPlanExpired, isPlanPerchased } = this.state;
    // console.log("isPlan", isPlanExpired, isPlanPerchased);
    return (
      <>
        <div>
          {isAuthenticated && (
            <div>
              {!isPlanPerchased ? (
                <span className="warning">
                  <Link to="/Subscriptionplan" className="buynow">
                    You don't have any plan buy now
                  </Link>
                </span>
              ) : isPlanExpired ? (
                <div className="warning">
                  Subscription expired
                  <Link className="renew-now" to="/Subscriptionplan">
                    <b className="renew"> Renew Now</b>
                  </Link>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div id="wrapper">
          {isAuthenticated && <Navigation />}
          {/*get successful auth token then its render next component   */}
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              {isAuthenticated && <Nav />}
              {this.props.children}
            </div>

            <div className="card-footer text-body-secondary">
              <div className="copyright text-center my-auto">
                <span style={{fontSize:15}}> &copy; Qik Innovation Pvt. Ltd.</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default App;
