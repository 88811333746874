import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom"; //use for linking purpose
import { Plans } from "./components/Plans.js";
import { Login } from "./components/Login";
import { Signup } from "./components/signup";
import { history } from "./services/history";
import { UserProfile } from "./components/UserProfile";
import { Terms } from "./components/Terms";
import "./App.css";
import { Forgetpass } from "./components/Forgetpass";
import DashboardRoutes from "./dashboard-routes";
import { isAuth } from "./utils/util";
import { Home } from "./components/Home";
import { Settings } from "./components/Settings";
import { MyApplication } from "./components/MyApplication";
import { Subscriptionplan } from "./components/Subscriptionplan";
import { Transactions } from "./components/Transactions";
import { Users } from "./components/Users";
import { Help } from "./components/Help";
import { Notification } from "./components/Notification";
const isAuthenticated = isAuth();
// const routeByRoles = getUserRole() ? routesByRole(getUserRole()) : [];
// const dropDown = () => {
//   var dropdown = document.getElementsByClassName("dropdown-btn");
//   var i;
//   for (i = 0; i < dropdown.length; i++) {
//     dropdown[i].addEventListener("click", function () {
//       this.classList.toggle("active");
//       var dropdownContent = this.nextElementSibling;
//       if (dropdownContent.style.display === "block") {
//         dropdownContent.style.display = "none";
//       } else {
//         dropdownContent.style.display = "block";
//       }
//     });
//   }
// };
const root = ReactDOM.createRoot(document.getElementById('root'));
// history={history}
root.render(
  <React.StrictMode>
    <HashRouter history={history}>
      <Switch>
        <Route
          exact
          path={"/"}
          render={(props) => (
            <Redirect to={isAuthenticated ? "/Home" : "/login"} />
          )}
        />
        <DashboardRoutes path="/Home" component={Home} />
        <DashboardRoutes path="/Settings" component={Settings} />
        <DashboardRoutes path="/MyApplication" component={MyApplication} />
        <DashboardRoutes path="/Notification" component={Notification} />

        <DashboardRoutes
          path="/Subscriptionplan"
          component={Subscriptionplan}
        />

        <DashboardRoutes path="/Transactions" component={Transactions} />
        <DashboardRoutes path="/Users" component={Users} />

        <DashboardRoutes path="/Plans" component={Plans} />
        <DashboardRoutes path="/UserProfile" component={UserProfile} />
        <Route path="/Login" component={Login} />
        <Route path="/Forgetpass" component={Forgetpass} />
        <Route path="/Signup" component={Signup} />
        <Route path="/Terms" component={Terms} />
        <DashboardRoutes path="/Help" component={Help} />
        <Route path="/*">
          <h3 style={{ textAlign: "center" }}>
            OOps !! It seems like you lost, going out of space, Please go to
            Home
          </h3>
        </Route>
      </Switch>
    </HashRouter>
  </React.StrictMode>,
  
);
serviceWorker.unregister();
