import React, { Component } from "react";
import "../plans.css";
import Parse from "parse";
import Loading from "./Loading";
import { history } from "../services/history";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import {
  getCurrentTenant,
  getHasplanPurchase,
  getSubUser,
  saveTransaction,
  setExpiryPlans,
  setHasplanPurchase,
  getUserRole,
} from "../utils/util";
// const Razorpay = require("razorpay"); //
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

// const __DEV__ = document.domain === "localhost"; //show the current domain name
export class Subscriptionplan extends Component {
  constructor() {
    super();
    this.state = this.initialState;
  }
  initialState = {
    planNames: ["Basic Plan", "Silver Plan", "Gold Plan", "Platinum Plan"], //plans name array
    availablePlans: [], //available 4 ,its array type state
    BasicPlan: {}, //basic plan object type state
    SilverPlan: {}, //silver plan object type state
    GoldPlan: {}, //gold plan object type state
    PlatinumPlan: {}, //platinum plan object type state
    modalData: {}, //pass data to the modal by using props
    loading: false, //initially loading is false
    paymentLoading: false, //payment loading on modal
    isSubUser: false, //initially subuser is false.
    subscribeModal: false,
    modalKey: "",
    showModal: false,
    UserRole: ["admin", "manager", "accounts"],
  };
  displayRazorpay = async (e, selectedPlan, amount, _billing) => {
    this.setState({ subscribeModal: false });
    // console.log("billling data", selectedPlan, amount, _billing);
    this.setState({ paymentLoading: true });
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      this.setState({ paymentLoading: false });
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    //This cloud function is to create order and return order details
    const order = await Parse.Cloud.run("CreateOrder", {
      amount: amount,
      currency: "INR",
      env: "dev", // __DEV__ ? "dev" : "prod", //show on either local or heroku app
    });
    const currentTenant = getCurrentTenant();
    let self = this; //in callback function we don't use "this",so here we use self this.
    const options = {
      key: "rzp_test_JuKUtrnG9SoyHk", //__DEV__ ? "rzp_test_JuKUtrnG9SoyHk" : "rzp_live_wtUytXxTyUkjds",
      currency: order.currency, //pass order to the options as a currency
      amount: order.amount, //pass order to the options as a amount
      order_id: order.id, //pass order to the options as a id
      name: "Qik.Ai", //pass order to the options as a name
      //function for success payment
      handler: async function (response) {
        //this is the callback function execute at the last
        //If the payment is successful then its return the following fields.
        const { razorpay_signature, razorpay_payment_id, razorpay_order_id } =
          response;
        self.setState({ loading: true });
        //payment order cloud functions
        const captureResponse = await Parse.Cloud.run("paymentCapture", {
          razorpay_payment_id,
          razorpay_signature,
          razorpay_order_id,
          amount: amount,
          currency: "INR",
          env: "dev", //__DEV__ ? "dev" : "prod", //show on either local or heroku app
        });
        //function for save successful transaction
        const { items } = captureResponse;
        if (items.length > 0) {
          const result = await saveTransaction(
            items, //all payment details
            _billing, //selected billing cycle
            amount, //selected plan amount
            selectedPlan, //selected plan
            razorpay_payment_id,
            razorpay_order_id,
            0 //days left
          );
          if (result) {
            // this.myFunction("Transaction done successfully.");

            //for migrate admin data from base db to client db
            try {
              const currentUser = await Parse.User.currentAsync();
              const user = JSON.parse(JSON.stringify(currentUser));
              if (user) {
                const orgQuery = new Parse.Query("orgApps");

                orgQuery.equalTo("superAdmin", currentUser);

                const res = await orgQuery.first();
                const result = JSON.parse(JSON.stringify(res));

                const parseAppId = "aprjnnhfcp";
                const serverUrl =
                  "https://root.qik.ai/app/functions/setupAppcreator";
                const data = {
                  orgApps: {
                    orgName: result.appName,
                  },
                  user: {
                    username: user.email,
                    name: user.name,
                    phone: user.phone,
                    objectId: user.objectId,
                  },
                };
                await axios
                  .post(`${serverUrl}`, data, {
                    headers: {
                      "Content-Type": "application/json",
                      "X-Parse-Application-Id": parseAppId,
                      sessiontoken :localStorage.getItem("auth_token")
                    },
                  })

                  .then((Listdata) => {
                    //  console.log('Listdata ', Listdata);

                    const json = Listdata.data.results;
                    console.log("json ", json);
                    return json;
                  })
                  .catch((err) => {
                    console.log("axois err ", err);
                  });
              }
            } catch (e) {
              console.log("mirgation to admin cloud function error:", e);
            }
            self.setState({ loading: true });
            setHasplanPurchase(true); //plan is  purchased.
            setExpiryPlans(false); //plan is not expired.
            setTimeout(() => {
              history.push("/Plans");
            });
          } else {
            self.setState({ loading: false });
          }
        } else {
          self.setState({ loading: false });
        }
      },
      //function for payment failure
      modal: {
        escape: false,
        ondismiss: async function () {
          self.setState({ loading: true });
          const captureResponse = await Parse.Cloud.run("paymentCapture", {
            razorpay_payment_id: null,
            razorpay_signature: null,
            razorpay_order_id: order.id,
            amount: amount,
            currency: "INR",
            env: "dev", // __DEV__ ? "dev" : "prod", //show on either local or heroku app
          });
          const { items } = captureResponse;
          if (items.length > 0) {
            const result = await saveTransaction(
              items, //payment details
              _billing, //selected billing cycle
              amount, //selected plan price
              selectedPlan, //selected plan
              null,
              null,
              0 //days left
            );
            if (result) {
              this.myFunction("Transaction failed. ");
              self.setState({ loading: false });
              history.push("/Plans");
            } else {
              self.setState({ loading: false });
            }
          } else {
            self.setState({ loading: false });
          }
        },
      },
      prefill: {
        //Cardholders name to be pre-filled when the Checkout opens.
        name: currentTenant.TenantName,
        email: currentTenant.EmailAddress,
        contact: currentTenant.ContactNumber,
      },
    };
    const paymentObject = new window.Razorpay(options); //show iframe in modal format
    this.setState({ paymentLoading: false });
    paymentObject.open();
  };

  //function for avail plan details
  subscriptionsDetails = async () => {
    if (getHasplanPurchase()) {
      history.push("/Plans");
    } else {
      const Tenant = Parse.Object.extend("partners_Plans");
      const query = new Parse.Query(Tenant);
      this.setState({ loading: true });
      query.include("PlanDetails");
      const result = await query.find();
      if (result) {
        const sortedPlans = [];
        this.state.planNames.forEach((p) => {
          sortedPlans.push(result.find((a) => a.attributes.PlanName === p)); //sort it using feature wise plans
          //basic,silver,gold,platinum
        });
        this.setState({ availablePlans: sortedPlans, loading: false });
      }
    }
  };

  componentDidMount() {
    this.subscriptionsDetails();
    const subuser = getSubUser(); //get subuser
    this.setState({ isSubUser: subuser }); //update subuser
    const userrole = getUserRole();

    this.setState({
      UserRole: userrole,
    });
  }

  onChangePlanPrice = (e, planArray) => {
    let valArr = e.target.value.split("-"); //The split() method is used to split a string into an
    // array of substrings, and returns the new array.
    // Note: The split() method does not change the original string.
    const activePlanObj = planArray.find((p) => p.duration === valArr[1]);
    this.setState({
      [valArr[0].replace(/ /g, "")]: activePlanObj,
      //If you are replacing a value (and not a regular expression), only the first instance of the value will be replaced.
      //To replace all occurrences of a specified value, use the global (g) modifier.
    });
  };
  myFunction = (msg) => {
    var x = document.getElementById("snackbar");
    x.className = "show";
    x.innerHTML = msg;
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };

  render() {
    const {
      availablePlans,
      resultFound,
      modalData,
      loading,
      paymentLoading,
      subscribeModal,
      modalKey,
      UserRole,
    } = this.state;

    //plan more option modal
    const PlanAdvantageModal = (props) => {
      const { data } = props;

      return (
        <Modal show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title>
              {" "}
              {/* {Object.entries(data).length > 0 && data?.name} Feature */}
            </Modal.Title>

            <button
              type="button"
              className="close "
              onClick={() => {
                this.setState({ showModal: false });
              }}
            >
              <img src="img/close.png" alt="closeimage" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <ul className="uvsplul">
                  {/* The entries() method returns an Array Iterator object with key/value pairs. */}
                  {data.length > 0 &&
                    data?.map((planAdvantage, key) => {
                      return (
                        <li key={key}>
                          <button className="btn btn-warning  btn-circle btn-uvs"></button>
                          {planAdvantage}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({ showModal: false });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div id="snackbar"></div>
          {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h3 className="h3 text-uvsh mb-4">Qik Admin Console -</h3>
          </div> */}
          {paymentLoading && (
            <div
              className="payment-loader"
              style={{
                margin: "0 auto",
                position: "absolute",
                height: "100vh",
                width: "100%",
              }}
            >
              <Loading type="spin" />
            </div>
          )}
          <div className="col-md-12">
            <h3 className="h3 text-uvsh mb-4">Qik Admin Console -</h3>
          </div>
          <div className="row uvsappss">
            {loading ? (
              <div style={{ margin: "0 auto" }}>
                <Loading />
              </div>
            ) : availablePlans && availablePlans.length > 0 ? (
              availablePlans.map((subscrib, key) => {
                let plansArr = [];

                for (let i in subscrib.attributes) {
                  let obj = {};
                  switch (i) {
                    case "Price1Mo":
                      obj["duration"] = "1 Month";
                      obj["actualPrice"] = subscrib.attributes[i];
                      obj["discountPrice"] = subscrib.get("Mrp1Mo");
                      obj["features"] = [];
                      obj["isDefault"] =
                        subscrib.get("DefaultBillingCycle") === "1 Month";
                      plansArr.push(obj);

                      break;
                    case "Price3Mo":
                      obj["duration"] = "3 Months";
                      obj["actualPrice"] = subscrib.attributes[i];
                      obj["discountPrice"] = subscrib.get("Mrp3Mo");
                      obj["features"] = [];
                      obj["isDefault"] =
                        subscrib.get("DefaultBillingCycle") === "3 Months";

                      plansArr.push(obj);
                      break;
                    case "Price6Mo":
                      obj["duration"] = "6 Months";
                      obj["actualPrice"] = subscrib.attributes[i];
                      obj["discountPrice"] = subscrib.get("Mrp6Mo");
                      obj["features"] = [];
                      obj["isDefault"] =
                        subscrib.get("DefaultBillingCycle") === "6 Months";
                      plansArr.push(obj);
                      break;
                    case "Price12Mo":
                      obj["duration"] = "1 Year";
                      obj["actualPrice"] = subscrib.attributes[i];
                      obj["discountPrice"] = subscrib.get("Mrp12Mo");
                      obj["features"] = [];
                      obj["isDefault"] =
                        subscrib.get("DefaultBillingCycle") === "1 Year";

                      plansArr.push(obj);
                      break;
                    case "Price36Mo":
                      obj["duration"] = "3 Years";
                      obj["actualPrice"] = subscrib.attributes[i];
                      obj["discountPrice"] = subscrib.get("Mrp36Mo");
                      obj["features"] = [];
                      obj["isDefault"] =
                        subscrib.get("DefaultBillingCycle") === "3 Years";

                      plansArr.push(obj);
                      break;
                    case "Price60Mo":
                      obj["duration"] = "5 Years";
                      obj["actualPrice"] = subscrib.attributes[i];
                      obj["discountPrice"] = subscrib.get("Mrp60Mo");
                      obj["features"] = [];
                      obj["isDefault"] =
                        subscrib.get("DefaultBillingCycle") === "5 Years";
                      plansArr.push(obj);
                      break;
                    default:
                      break;
                  }
                }
                //set plan images background,actual price and discount price
                let backgroundImage = {
                  background: "",
                  backgroundSize: "100% 100%",
                  padding: "20px",
                };
                const { BasicPlan, SilverPlan, GoldPlan, PlatinumPlan } =
                  this.state;
                let discountPrice,
                  actualPrice,
                  amount,
                  billingCycle,
                  defualt,
                  defaultPrice,
                  defaultDiscount;

                if (subscrib.get("PlanName") === "Basic Plan") {
                  backgroundImage.background = `url("img/premium.png")`;
                  const defaultObj =
                    plansArr.filter((p) => p.isDefault)[0] || plansArr[0];
                  discountPrice =
                    BasicPlan.discountPrice || defaultObj.discountPrice;
                  actualPrice = BasicPlan.actualPrice || defaultObj.actualPrice;
                  defaultPrice = defaultObj.actualPrice;
                  defaultDiscount = defaultObj.discountPrice;
                  amount = discountPrice ? discountPrice : actualPrice;
                  billingCycle = BasicPlan.duration;
                  defualt = subscrib.get("DefaultBillingCycle");
                }
                if (subscrib.get("PlanName") === "Silver Plan") {
                  backgroundImage.background = `url("img/basic.png")`;
                  const defaultObj =
                    plansArr.filter((p) => p.isDefault)[0] || plansArr[0];
                  discountPrice =
                    SilverPlan.discountPrice || defaultObj.discountPrice;
                  actualPrice =
                    SilverPlan.actualPrice || defaultObj.actualPrice;
                  defaultPrice = defaultObj.actualPrice;
                  defaultDiscount = defaultObj.discountPrice;
                  amount = discountPrice ? discountPrice : actualPrice;
                  billingCycle = SilverPlan.duration;
                  defualt = subscrib.get("DefaultBillingCycle");
                }
                if (subscrib.get("PlanName") === "Gold Plan") {
                  backgroundImage.background = "url('img/gold.png')";
                  const defaultObj =
                    plansArr.filter((p) => p.isDefault)[0] || plansArr[0];
                  discountPrice =
                    GoldPlan.discountPrice || defaultObj.discountPrice;
                  actualPrice = GoldPlan.actualPrice || defaultObj.actualPrice;
                  defaultPrice = defaultObj.actualPrice;
                  defaultDiscount = defaultObj.discountPrice;
                  amount = discountPrice ? discountPrice : actualPrice;
                  billingCycle = GoldPlan.duration;
                  defualt = subscrib.get("DefaultBillingCycle");
                }

                if (subscrib.get("PlanName") === "Platinum Plan") {
                  backgroundImage.background = `url("img/premium.png")`;
                  const defaultObj =
                    plansArr.filter((p) => p.isDefault)[0] || plansArr[0];
                  discountPrice =
                    PlatinumPlan.discountPrice || defaultObj.discountPrice;
                  actualPrice =
                    PlatinumPlan.actualPrice || defaultObj.actualPrice;
                  defaultPrice = defaultObj.actualPrice;
                  defaultDiscount = defaultObj.discountPrice;
                  amount = discountPrice ? discountPrice : actualPrice;
                  billingCycle = PlatinumPlan.duration;
                  defualt = subscrib.get("DefaultBillingCycle");
                }
                const subscribeModalFun = (
                  ind,
                  discountPrice,
                  actualPrice,
                  defualtDay
                ) => {
                  const biilingSub = billingCycle ? billingCycle : defualt;
                  return (
                    <Modal show={this.state.subscribeModal}>
                      <Modal.Header>
                        <Modal.Title>Payment</Modal.Title>
                        <button
                          type="button"
                          className="close "
                          onClick={() => {
                            actualPrice = defaultPrice;
                            discountPrice = defaultDiscount;
                            this.setState({
                              subscribeModal: false,
                              BasicPlan: {},
                              SilverPlan: {},
                              GoldPlan: {},
                              PlatinumPlan: {},
                            });
                          }}
                        >
                          <img src="img/close.png" alt="closeimage" />
                        </button>
                      </Modal.Header>
                      <Modal.Body>
                        <table className="uvsplanss">
                          <tbody>
                            <tr>
                              <td style={backgroundImage}>
                                <h3 className="h3 text-uvsh">
                                  <select
                                    onChange={(e) =>
                                      this.onChangePlanPrice(e, plansArr)
                                    }
                                    className=" form-control-uvs d-sm-inline-block shadow"
                                    aria-label="Default select example"
                                    //  value={defualt}

                                    // defaultValue={`${subscrib.get(
                                    //   "PlanName"
                                    // )}-${subscrib.get("DefaultBillingCycle")}`}
                                  >
                                    {plansArr.map((d, i) => {
                                      return (
                                        <option
                                          key={`${d.duration}${i}`}
                                          value={`${subscrib.get("PlanName")}-${
                                            d.duration
                                          }`}
                                          selected={
                                            d.duration === defualtDay && true
                                          }
                                        >
                                          {/* {defualtDay} */}
                                          {d.duration}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </h3>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="plan-amount">Plan Amount</span>
                                <s className="uvsright">
                                  <img src="img/inr.png" alt="price" />
                                  {actualPrice}
                                </s>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="discount">
                                  Discounted Price
                                </span>
                                <span>
                                  <strong className="uvsright ">
                                    <img src="img/inr.png" alt="price" />
                                    {discountPrice}
                                  </strong>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button
                          variant="primary"
                          onClick={(e) => {
                            this.displayRazorpay(
                              e,
                              subscrib,
                              amount,
                              biilingSub
                            );
                          }}
                        >
                          Pay
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  );
                };

                return (
                  <div key={subscrib.id} className="col-md-4">
                    <div className="card shadow mb-4" style={{ padding: 0 }}>
                      {subscribeModal &&
                        key === modalKey &&
                        subscribeModalFun(
                          key,
                          discountPrice,
                          actualPrice,
                          defualt
                          // subscrib.get("DefaultBillingCycle")
                        )}
                      <table className="uvsplanss">
                        <tbody>
                          <tr>
                            <td style={backgroundImage}>
                              <h3 className="h3 text-uvsh">
                                {subscrib.get("PlanName")}
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <ul className="uvsplul">
                                {subscrib.get("PlanDetails") &&
                                subscrib.get("PlanDetails").length > 0
                                  ? subscrib
                                      .get("PlanDetails")
                                      .map((planAdvantage, key) => {
                                        return (
                                          <li key={key}>
                                            <button className="btn btn-primary btn-circle btn-uvs"></button>
                                            {planAdvantage}
                                          </li>
                                        );
                                      })
                                  : resultFound && (
                                      <div>
                                        No result found please check your filter
                                      </div>
                                    )}
                              </ul>
                            </td>
                          </tr>

                          <tr>
                            <td className="text-center">
                              <button
                                className="btn-block btn btnvss  "
                                onClick={(e) => {
                                  this.setState({
                                    modalData: subscrib.get("PlanDetails"),
                                    showModal: true,
                                  });
                                }}
                              >
                                More
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <button
                                className="btn-block btn btnvss btn-warning"
                                disabled={UserRole === "manager"}
                                onClick={(e) => {
                                  this.setState({
                                    modalKey: key,
                                    subscribeModal: true,
                                    selectedPlanName:
                                      subscrib.attributes.PlanName,
                                  });
                                }}
                              >
                                Buy Now
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })
            ) : (
              resultFound && <div>No result found please check your filter</div>
            )}
          </div>
        </div>
        <PlanAdvantageModal data={modalData} />
      </React.Fragment>
    );
  }
}
