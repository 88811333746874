import React, { useEffect, useState } from "react";
import "../Login.css";

import Parse from "parse";
import "../Snackbar.css";
import {
  setAuthToken,
  setCurrentTenant,
  setExpiryPlans,
  setHasplanPurchase,
  setSubUser,
  getSubUser,
  setUserRole,
  registerTenant,
  getCurrentTenant,
} from "../utils/util";
import { history } from "../services/history";
import { Link } from "react-router-dom";
import { emailValidator } from "../utils/validator";
import moment from "moment";
export const Login = (props) => {
  //initialization constructor method executed first.
  const [state, setState] = useState({
    email: "",
    password: "",
    isChecked: false,
    loadingProgress: false,
  });

  //login details validation
  //If you use the async keyword before a function definition.
  //The async function returns a promise .....
  const url = window.location.host;
  window.onmessage = function (e) {
    debugger;
    try {
      if (e.origin === url) {
        return;
      }
      var payload = JSON.parse(e.data);
      switch (payload.method) {
        case "set":
          localStorage.clear();
          for (const [key, value] of Object.entries(payload.data)) {
            localStorage.setItem(`${key}`, JSON.stringify(`${value}`));
          }
          localStorage.setItem("SwitcherApp", "true");
          onSessionAvailable(JSON.parse(localStorage.getItem("auth_token")));
          break;
        case "get":
          var parent = window.parent;
          var data = localStorage.getItem(payload.key);
          parent.postMessage(data, "*");
          break;
        case "remove":
          localStorage.removeItem(payload.key);
          break;
        default:
          break;
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (localStorage.checkbox && localStorage.email !== "") {
      setState({
        ...state,
        email: localStorage.username,
        password: localStorage.password,
      });
    }
    // eslint-disable-next-line
  }, []);
  const onChangeValue = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeCheckbox = (event) => {
    setState({
      ...state,
      isChecked: !this.state.isChecked,
    });
  };
  const loginUser = async (e) => {
    e.preventDefault(); //preventDefault is called on the event when submitting the form to prevent a browser reload/refresh
    const { email, password, isChecked } = state;
    try {
      if (!emailValidator.test(email)) {
        setState({
          ...state,
          emailError: "Please enter valid email!",
        });
      } else {
        setState({
          ...state,
          emailError: "",
          loadingProgress: true,
        });
        if (isChecked && email !== "") {
          localStorage.username = email;
          localStorage.password = password;
        }
        const user = await Parse.User.logIn(email, password);
        // console.log("login user",user)

        if (user) {
          // console.log("user")
          //if user exist from other system,make entry into our tenant class
          //login using if user not exist

          localStorage.setItem(
            "userPic",
            JSON.stringify({
              src: user?.attributes?.ProfilePic
                ? user?.attributes?.ProfilePic
                : "",
            })
          );
          const subUser = Parse.Object.extend("partners_SubUsers"),
            querysubUser = new Parse.Query(subUser);
          querysubUser.equalTo("UserId", user);
          querysubUser.include("TenantId");
          const subUserResult = await querysubUser.first();

          if (subUserResult) {
            setSubUser(true); //is subuser
          } else {
            setSubUser(false); //not subuser
          }
          const Tenant = Parse.Object.extend("partners_Tenant"),
            queryTenant = new Parse.Query(Tenant);
          queryTenant.equalTo("UserId", user);
          let tenantResult = await queryTenant.first();
          let subUserTenant;
          if(subUserResult){
            subUserTenant = subUserResult.attributes.TenantId
          }

          
          const tenantData = tenantResult ? tenantResult : subUserTenant  ;
          const partnerUser = tenantResult || subUserResult;
        //  console.log("tenantSubClass",subUserResult.attributes.TenantId)
          if (partnerUser) {
            if (!partnerUser.attributes.IsActive) {
              Parse.User.logOut();
              localStorage.clear();
              setState({
                ...state,
                passError:
                  "Your account is Inactive! please contact to your admin",
                loadingProgress: false,
              });
              return;
            }
            myFunction("Login Successful");
          } else {
            registerTenant(user, true);
            tenantResult = getCurrentTenant();
          }
          const subscriptions = Parse.Object.extend("partners_Subscriptions"),
            querySubscriptions = new Parse.Query(subscriptions);
          querySubscriptions.include("PlansPtr");
          querySubscriptions.equalTo("TenantId", tenantData);
          querySubscriptions.equalTo("TxnStatus", "Success");
          const subscriptionsResult = await querySubscriptions.find();
          console.log("subscription",subscriptionsResult)
          if (subscriptionsResult.length > 0) {
            console.log("subscription",subscriptionsResult)
            setHasplanPurchase(true); //plan Purchase

            const filtertrnasactions = subscriptionsResult.filter(
                (tr) =>
                  new Date().getTime() <=
                  new Date(`${tr.attributes.ToDate}`).getTime() //Template literals are string literals allowing embedded expressions.
                // You can use multi-line strings and string interpolation features with them.
              ),
              sortedTransactions =
                filtertrnasactions &&
                filtertrnasactions.sort((a, b) => b.updatedAt - a.updatedAt),
              a = moment(sortedTransactions[0].attributes.ToDate),
              b = moment(sortedTransactions[0].attributes.FromDate),
              daysLeft = a.diff(b, "days");

            setState({
              ...state,
              transactions: subscriptionsResult,
              daysLeft: daysLeft,
            });
            if (daysLeft > 0) {
              setExpiryPlans(false); //plan is not expired
            } else {
              setExpiryPlans(true); //plan is  expired
            }
          } else {
            setHasplanPurchase(false); //plan not Purchase
            setExpiryPlans(true); //plan is expired
          }
          //  find into subusers class if not get in tenantResult
          //if we want to tenant details as well as subusers details
          if (user && (tenantResult || subUserResult)) {
            setAuthToken(user.get("sessionToken"));
            setCurrentTenant(
              tenantResult ? tenantResult : subUserResult.attributes.TenantId
            );
            setUserRole(
              tenantResult ? "admin" : subUserResult.attributes.UserRole
            );
            setState({ ...state, loadingProgress: false }); //
            history.push("/Home");
          }
        } else {
          myFunction("Invalid username/password.");
          setState({
            ...state,
            passError: "Invalid username/password.",
            loadingProgress: false,
          });
        }
      }
    } catch (error) {
      myFunction(error.message);
      console.log("error", error);
      setState({ ...state, passError: error.message, loadingProgress: false });
    }
  };
  const onSessionAvailable = async (token) => {
    debugger;
    try {
      await Parse.User.become(token).then(
        async function (user) {
          if (user) {
            //if user exist from other system,make entry into our tenant class
            //login using if user not exist
            localStorage.setItem(
              "userPic",
              JSON.stringify({
                src: user?.attributes?.ProfilePic
                  ? user?.attributes?.ProfilePic
                  : "",
              })
            );
            const subUser = Parse.Object.extend("partners_SubUsers"),
              querysubUser = new Parse.Query(subUser);
            querysubUser.equalTo("UserId", user);
            querysubUser.include("TenantId");
            const subUserResult = await querysubUser.first();
            if (subUserResult) {
              setSubUser(true); //is subuser
            } else {
              setSubUser(false); //not subuser
            }
            const Tenant = Parse.Object.extend("partners_Tenant"),
              queryTenant = new Parse.Query(Tenant);
            queryTenant.equalTo("UserId", user);

            let tenantResult = await queryTenant.first();
            if (!tenantResult && !getSubUser("isSubUser")) {
              registerTenant(user, true);
              tenantResult = getCurrentTenant();
            }
            const subscriptions = Parse.Object.extend("partners_Subscriptions"),
              querySubscriptions = new Parse.Query(subscriptions);
            querySubscriptions.include("PlansPtr");
            querySubscriptions.equalTo("TenantId", tenantResult);
            querySubscriptions.equalTo("TxnStatus", "Success");
            const subscriptionsResult = await querySubscriptions.find();
            if (subscriptionsResult.length > 0) {
              setHasplanPurchase(true);
              const filtertrnasactions = subscriptionsResult.filter(
                  (tr) =>
                    new Date().getTime() <=
                    new Date(`${tr.attributes.ToDate}`).getTime() //Template literals are string literals allowing embedded expressions.
                  // You can use multi-line strings and string interpolation features with them.
                ),
                sortedTransactions = filtertrnasactions.sort(
                  (a, b) => b.updatedAt - a.updatedAt
                ),
                a = moment(sortedTransactions[0].attributes.ToDate),
                b = moment(sortedTransactions[0].attributes.FromDate),
                daysLeft = a.diff(b, "days");
              setState({
                ...state,
                transactions: subscriptionsResult,
                daysLeft: daysLeft,
              });
              if (daysLeft > 0) {
                setExpiryPlans(false); //plan is not expired
              } else {
                setExpiryPlans(true); //plan is  expired
              }
            } else {
              setHasplanPurchase(false); //plan not Purchase
              setExpiryPlans(true); //plan is expired
            }
            //  find into subusers class if not get in tenantResult
            //if we want to tenant details as well as subusers details

            if (user && (tenantResult || subUserResult)) {
              setAuthToken(user.get("sessionToken"));
              setCurrentTenant(
                tenantResult ? tenantResult : subUserResult.attributes.TenantId
              );
              setUserRole(
                tenantResult ? "admin" : subUserResult.attributes.UserRole
              );
              setState({ ...state, loadingProgress: false }); //
              history.push("/Home");
            }
          } else {
            setState({
              ...state,
              passError: "Invalid username/password.",
              loadingProgress: false,
            });
          }
        },
        function (error) {
          // The token could not be validated.
        }
      );
    } catch (error) {}
  };
  const myFunction = (msg) => {
    var x = document.getElementById("snackbar");
    x.className = "show";
    x.innerHTML = msg;
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  const { loadingProgress, email, password, isChecked } = state; //extract state
  return (
    <div style={{ overflow: "hidden" }}>
      <div id="snackbar"></div>
      <div className="row">
        <div className="col-md-5">
          <div className="uvsround">
            <img src="img/logo.svg" alt="logoimage" />
            <h3>
              <br />
              Welcome Back !
            </h3>
            <p>
              <b>Login to Qik admin console Application.</b>
              <br />
            </p>
            Email Address
            <input
              type="text"
              id="email"
              name="email"
              value={email}
              className="form-control"
              onChange={onChangeValue}
            />
            <div className="error-div">{state.emailError}</div>
            Password
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              className="form-control"
              onChange={onChangeValue}
            />
            <div className="error-div">{state.passError}</div>
            <input
              type="checkbox"
              name="lsRememberMe"
              onChange={onChangeCheckbox}
              value={isChecked}
            />
            &nbsp; Remember Password &nbsp;
            <Link to="/Forgetpass"> Forgot Password ?</Link>
            <br />
            <button
              className="btn btn-primary "
              onClick={(e) => {
                loginUser(e);
              }}
            >
              Login &nbsp;
              {loadingProgress && <i className="fa fa-spinner fa-spin"></i>}
            </button>
            <br /> Don't have an account?
            <Link to="/Signup"> Create an account</Link>
          </div>
        </div>
        <div className="col-md-7">
          <div
            className="uvsroundbg text-center"
            style={{
              background: "url(img/bg.png)",
              backgroundRepeat: "round",
            }}
          >
            <img src="img/login.png" alt="loginimage" className="uvsimg" />
          </div>
        </div>
      </div>
    </div>
  );
};
