import React, { Component } from "react";
import "./Help.css";
export class Help extends Component {
  render() {
    return (
      <main className="wrap">
        <section className="container">
          <div className="container__heading">
            <h2 className="heading">How can we help you?</h2>
          </div>
          <div className="container__nav"></div>
        </section>
      </main>
    );
  }
}
