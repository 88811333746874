import React, { Component } from "react";
import "../Forgetpass.css";
import Parse from "parse";
import { emailValidator } from "../utils/validator";
import { Link } from "react-router-dom";
export class Forgetpass extends Component {
  state = { forgetemail: "", password: " " }; //needed state
  passwordReset = async (e) => {
    const { forgetemail } = this.state;
    try {
      //first check validation
      if (!emailValidator.test(this.state.forgetemail)) {
        this.setState({
          emailError: "Email must contain '@' symbol,Please enter valid email",
        });
        //after extend User class
      } else {
        const user = Parse.Object.extend("_User"),
          //query to the User class
          query = new Parse.Query(user);
        query.equalTo("username", forgetemail);
        const result = await query.first();
        // console.log("result ", result);
        if (result) {
          //requestPasswordReset is a parse password reset method
          //it take always email as a parameter.
          const resetResult = await Parse.User.requestPasswordReset(
            forgetemail
          );

          if (resetResult) {
            alert("Link has been sent in your registered email successfully.");
          }
        } else {
          alert("Email does not exist.");
        }
        this.setState({
          emailError: "",
        });
      }
    } catch (error) {
      alert("Something went wrong, Please try again" + error);
    }
  };
  changeHandler = (event) => {
    const value = event.target.value;
    if (value) {
      // check validation if value is exist then update the state.
      this.setState({
        forgetemail: value, //update state
      });
    }
  };
  render() {
    return (
      <div className="main-email-wrapper">
        <form className="forget-form">
          <p className="forget-heading" style={{ color: "#172B4D" }}>
            Reset Your Password !
          </p>
          <input
            type="text"
            id="forgetemail"
            placeholder="Enter your mail"
            className="form-control"
            onChange={this.changeHandler}
          />
          <p className="reset-pass">
            You will receive link to your registered email, to reset password
          </p>
          <div className="error-div">{this.state.emailError}</div>
          <div className="forget-btn">
            <button
              onClick={(e) => {
                this.passwordReset(e);
              }}
              className="btn btn-info spacing btn-s"
              type="button"
              value="submit"
            >
              Submit
            </button>
            <Link to="/Login" className="spacing">
              Login Now
            </Link>
          </div>
        </form>
      </div>
    );
  }
}
