import Parse from "parse";
import { history } from "../services/history";
//image upload function
import axios from "axios";
export const imageUploadAPI =
"https://root.qik.ai/image_upload"
// "https://dolphin-app-7d2xp.ondigitalocean.app/image_upload"
// "https://server2.qik.ai/image_upload";
//data access globally.same as the component in react
//  ex:  redux .....shareable storage
//function for set setAuthToken store in localstorage
export const setAuthToken = (token) => {
  localStorage.setItem("auth_token", JSON.stringify(token)); //stringify() takes a JavaScript object and then transforms it into a JSON string.
};
//function for getAuthToken
export const getAuthToken = () => {
  const token = localStorage.getItem("auth_token");
  if (token) {
    return JSON.parse(token); //parse() takes a JSON string and then transforms it into a JavaScript object.
  }
  return null;
};
//function for setCurrentTenant store in local storage
export const setCurrentTenant = (tenant) => {
  localStorage.setItem("current_tenant", JSON.stringify(tenant));
};
//function for getCurrentTenant
export const getCurrentTenant = () => {
  const tenant = localStorage.getItem("current_tenant");
  if (tenant) {
    return JSON.parse(tenant);
  }
  return null;
};
//logout function
export const logoutUser = (event) => {
  event.preventDefault();
  Parse.User.logOut();

  localStorage.clear();
  history.push("/login");
};
//set authentication
export const isAuth = () => (getAuthToken() ? true : false);
//compare for ascending and descending function
export const compareValues = (key, order = "asc") => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};
//function for setExpiryPlans
export const setExpiryPlans = (expired) => {
  localStorage.setItem("isPlanExpired", JSON.stringify(expired));
};
//function for setHasplanpurchas
export const setHasplanPurchase = (purchase) => {
  localStorage.setItem("isPlanPerchased", JSON.stringify(purchase));
};
//function for getHasplanPurchas
export const getHasplanPurchase = () => {
  const purchase = localStorage.getItem("isPlanPerchased");
  if (purchase) {
    return JSON.parse(purchase);
  }
  return null;
};
//function for getExpiryPlans
export const getExpiryPlans = () => {
  const expired = localStorage.getItem("isPlanExpired");
  if (expired) {
    return JSON.parse(expired);
  }
  return null;
};
//function for getUserProfile
export const getUserProfile = () => {
  const profile = localStorage.getItem("userPic");
  if (profile) {
    return JSON.parse(profile);
  }
};
//function for setUserProfile
export const setUserProfile = (profile) => {
  localStorage.setItem("userPic", JSON.stringify(profile));
};
//function for getSubUser

export const getSubUser = () => {
  const subuser = localStorage.getItem("isSubUser");
  if (subuser) {
    return JSON.parse(subuser);
  }
};
//function for setSubUser
export const setSubUser = (subuser) => {
  localStorage.setItem("isSubUser", JSON.stringify(subuser));
};

export const getUserRole = () => {
  const userrole = localStorage.getItem("UserRole");
  if (userrole) {
    return JSON.parse(userrole);
  }
};
export const getSessionToken = () => {
  const sessionToken = localStorage.getItem("auth_token");
  if (sessionToken) {
    return sessionToken;
  }
};
//function for setSubUser
export const setUserRole = (userrole) => {
  localStorage.setItem("UserRole", JSON.stringify(userrole));
};

export const clearUserRole = () => {
  localStorage.removeItem("UserRole");
};

// -------------------------
//set flag isexpired after purchase new plan
// plan purchase -true
// plan expired-false
// const getNumberOfDays = (start, end) => {
//   const date1 = new Date(start);
//   const date2 = new Date(end);

//   // One day in milliseconds
//   const oneDay = 1000 * 60 * 60 * 24;

//   // Calculating the time difference between two dates
//   const diffInTime = date2.getTime() - date1.getTime();
//   // Calculating the no. of days between two dates
//   const diffInDays = Math.round(diffInTime / oneDay);
//   console.log("diff", diffInDays, diffInTime);
//   return diffInDays;
// };
 
export const saveTransaction = async (
  items, //payment details
  _billing, //selected billing cycle
  amount, //selected plan amount
  selectedPlan, //selected plan
  razorpay_payment_id,
  razorpay_order_id,
  toDate
) => {
  //Promise.all is actually a promise that takes an array of promises as an input (an iterable).
  //Then it gets resolved when all the promises get resolved or any one of them gets rejected.
  //One interesting thing about Promise.all is that the order of the promises is maintained.
  //Promise.all waits till all the promises resolve
  console.log(
    "selectedPlan",
    selectedPlan,
    selectedPlan.attributes.EmailCredit,
    selectedPlan.attributes.SmsCredit
  );
  //for save sms,whatsapp,email credits count in client db
  const isPlanPerchased = getHasplanPurchase();
 
  console.log("isPlan",isPlanPerchased)
  try {
    
    const emailCredits = selectedPlan.attributes.EmailCredit;
    const smsCredits = selectedPlan.attributes.SmsCredit;
    const tenantResult = getCurrentTenant();

    const adminID = tenantResult.UserId.objectId;
    const orgQuery = new Parse.Query("orgApps");

    orgQuery.equalTo("superAdmin", {
      __type: "Pointer",
      className: "_User",
      objectId: adminID,
    });
    const res = await orgQuery.first();
    const result = JSON.parse(JSON.stringify(res));
    console.log("result", result, result.appId, result.publicServerURL);
    const parseAppId = result.appId;
    const serverUrl = result.publicServerURL;
    let data,whatsappCredit;
    if(selectedPlan.attributes.WhatsappCredit){
      whatsappCredit = selectedPlan.attributes.WhatsappCredit
       data = {
        email: emailCredits,
        sms: smsCredits,
        whatsapp:whatsappCredit,
        storage:0,
      };   
    }else{

       data = {
        email: emailCredits,
        sms: smsCredits,
        whatsapp:0,
        storage:0,
      };
    }
       
    
       if(isPlanPerchased){
         
  console.log("isPlan",isPlanPerchased)
       const notifyData = await axios
        .get(`${serverUrl}/classes/partners_TenantCredits`, {
          headers: {
            "Content-Type": "application/json",
            "X-Parse-Application-Id": parseAppId,
          },
        })
        .then((result) => {
          const res = result.data;
          //  console.log("res", res);
         
          return res.results;
        })
        .catch((err) => {
          console.log("axois err ", err);
        });
        console.log("response",notifyData[0].email ,notifyData[0].sms,notifyData[0].whatsapp)
        data = {
          email: notifyData[0].email + emailCredits,
          sms: notifyData[0].sms+smsCredits,
          whatsapp: whatsappCredit ? whatsappCredit + notifyData[0].whatsapp : notifyData[0].whatsapp + 0,
        };
        await axios
        .put(
          `${serverUrl}/classes/partners_TenantCredits/${notifyData[0].objectId}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": parseAppId,
            },
          }
        )
        .then((result) => {
          const res = result.data;
          console.log("save res", res);
        })
        .catch((err) => {
          console.log("axois err ", err);
        });
       }else{
 
        console.log("isPlan",isPlanPerchased)
         await axios
           .post(`${serverUrl}/classes/partners_TenantCredits`, data, {
             headers: {
               "Content-Type": "application/json",
               "X-Parse-Application-Id": parseAppId,
             },
           })
           .then((result) => {
             const res = result.data;
             console.log("res", res);
             localStorage.setItem("NotificationId",`${res.objectId}`)
           // localStorage.setItem({isNotificationId:res.objectId})
           })
           .catch((err) => {
             console.log("axois err ", err);
           });
       }
  } catch (e) {
    console.log("org app error", e);
  }

  const saveResult = await Promise.all(
    // The map() method creates a new array with the results of calling a function for every array element.
    items.map(async (item) => {
      const { method, order_id, status, fees, created_at } = item; //extract item element
      const Subscriptions = Parse.Object.extend("partners_Subscriptions"),
        Tenant = Parse.Object.extend("partners_Tenant"),
        tenantResult = getCurrentTenant(),
        tenantPointer = Tenant.createWithoutData(tenantResult.objectId);
      const subscription = new Subscriptions();

      

      let date;
      if(toDate){
        date = new Date(toDate);
        
      }else{
        
        date = new Date();
      }
      
      function addMonths(months) {
        date.setMonth(date.getMonth() + months);
        
        return date;
      }
      function addYears(years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
      }
      console.log("todate",date)
      let billingDate;
      switch (_billing) {
        case "1 Month":
          billingDate = addMonths(1);
          break;
        case "3 Months":
          billingDate = addMonths(3);
          break;
        case "6 Months":
          billingDate = addMonths(6);
          break;
        case "1 Year":
          billingDate = addYears(1);
          break;
        case "3 Years":
          billingDate = addYears(3);
          break;
        case "5 Years":
          billingDate = addYears(5);
          break;
        default:
          break;
      }
       
      let fromDate;
     
      // console.log("todate",);
      if (toDate) {
       fromDate = new Date(toDate)
      }else{
        fromDate = new Date(created_at * 1000);
      }

      // console.log("billingDate", billingDate,);
      subscription.set("FromDate", fromDate);
      subscription.set("ToDate", billingDate);
      subscription.set("TxnDate", new Date());
      subscription.set("TxnId", order_id);
      subscription.set(
        "TxnStatus",
        status === "captured" ? "Success" : "Failed"
      );
      subscription.set("TxnType", method);
      subscription.set("Price", amount);
      subscription.set("TxnFees", fees);
      subscription.set("TxnAmont", amount);
      subscription.set("PlansPtr", selectedPlan);
      subscription.set("CGST", 0);
      subscription.set("SGST", 0);
      subscription.set("IGST", 0);
      subscription.set("Razorpay_order_id", razorpay_order_id);
      subscription.set("Razorpay_payment_id", razorpay_payment_id);
      subscription.set("Razorpay_signature", null);
      subscription.set("DefaultBillingCycle", _billing);
      subscription.set("TenantId", tenantPointer);
      return subscription.save();
    })
  );
  if (saveResult) {
    console.log("saveResult", saveResult);
    return saveResult;
  }
};

export const saveCreditsTransaction = async (
  items, //payment details
  amount, //selected plan amount
  razorpay_payment_id,
  razorpay_order_id
) => {
  //Promise.all is actually a promise that takes an array of promises as an input (an iterable).
  //Then it gets resolved when all the promises get resolved or any one of them gets rejected.
  //One interesting thing about Promise.all is that the order of the promises is maintained.
  //Promise.all waits till all the promises resolve
  console.log(
    "saveCreditTransaction",
    items,
    amount,
    razorpay_order_id,
    razorpay_payment_id
  );
  const saveResult = await Promise.all(
    // The map() method creates a new array with the results of calling a function for every array element.
    items.map(async (item) => {
      const { method, order_id, status, fees } = item; //extract item element
      const Subscriptions = Parse.Object.extend("partners_Transactions"),
        Tenant = Parse.Object.extend("partners_Tenant"),
        tenantResult = getCurrentTenant(),
        tenantPointer = Tenant.createWithoutData(tenantResult.objectId);
      const subscription = new Subscriptions();
      subscription.set("TxnDate", new Date());
      subscription.set("TxnId", order_id);
      subscription.set(
        "TxnStatus",
        status === "captured" ? "Success" : "Failed"
      );
      subscription.set("TxnType", method);
      subscription.set("TxnFees", fees);
      subscription.set("TxnAmont", amount);
      subscription.set("CGST", 0);
      subscription.set("SGST", 0);
      subscription.set("IGST", 0);
      subscription.set("Razorpay_order_id", razorpay_order_id);
      subscription.set("Razorpay_payment_id", razorpay_payment_id);
      subscription.set("Razorpay_signature", null);

      subscription.set("TenantId", tenantPointer);
      return subscription.save();
    })
  );
  if (saveResult) {
    console.log("credits money", saveResult);
    return saveResult;
  }
};

const setCurrentTenantPostRegister = async (user) => {
  const Tenant = Parse.Object.extend("partners_Tenant"),
    queryTenant = new Parse.Query(Tenant);
  queryTenant.equalTo("UserId", user);
  const tenantResult = await queryTenant.first();
  setCurrentTenant(tenantResult);
};
export const registerTenant = async (user, isTosetCurrentTenant, orgName) => {
  try {
    const Tenant = Parse.Object.extend("partners_Tenant"),
      //create instance of Tenant class
      //  //GJUwjkqgaA
      tenant = new Tenant();
    const Partners = Parse.Object.extend("partners_Partners"),
      queryPartners = new Parse.Query(Partners);
    queryPartners.equalTo("objectId", "GACec6ePKA"); //this is the object id of amol@nxglabs.in partners.
    const partnersFirst = await queryPartners.first();
    if (partnersFirst) {
      tenant.set("PartnersId", partnersFirst);
    }
    //extend Tenant class
    tenant.set("TenantName", user.get("name")); //by using instance, set TenantName
    tenant.set("EmailAddress", user.get("email"));
    tenant.set("BusinessName", orgName);
    tenant.set("CreatedBy", user);
    tenant.set("ContactNumber", user.get("phone"));
    tenant.set("Address", "");
    tenant.set("State", "");
    tenant.set("Country", "");
    tenant.set("PinCode", "");
    tenant.set("IsActive", true);
    tenant.set("City", "");
    tenant.set("Password", user.get("password"));
    tenant.set("UserId", user);
    await tenant.save(); //save register user details.
    if (isTosetCurrentTenant && user) {
      setCurrentTenantPostRegister(user);
    }

    
  } catch (e) {
    console.log("tenant error:", e);
  }
  //console.log("get user id",user.id)
};
