import React, { Component } from "react";
import "../Transactions.css";

import Loading from "./Loading";
import { Modal, Button } from "react-bootstrap";
import { getCurrentTenant } from "../utils/util";
import moment from "moment";
import Parse from "parse";
import MaterialTable, { MTableBody } from "@material-table/core";
import { TableCell, TableFooter, TableRow } from "@material-ui/core";

export class Transactions extends Component {
  constructor() {
    super();
    this.state = this.initialState;
  }
  initialState = {
    trnasactions: [],
    loading: false,
    order_id: "",
    payment_id: "",
    billing_id: "",
    showModal: false,
  };
  //function for current tenant transaction details
  currentTenattransDetails = async () => {
    const Tenant = Parse.Object.extend("partners_Tenant"),
      tenantResult = getCurrentTenant(),
      tenantPointer = Tenant.createWithoutData(tenantResult.objectId),
      Subscriptions = Parse.Object.extend("partners_Subscriptions"),
      query = new Parse.Query(Subscriptions);
    query.include("PlansPtr");
    this.setState({ loading: true });
    query.equalTo("TenantId", tenantPointer);
    query.notEqualTo("IsDeleted", true);
    const result = await query.find();
    const ascdResult = result.reverse();
    console.log("result", result);
    if (result) {
      this.setState({ trnasactions: ascdResult, loading: false });
    }
  };
  // Delete=async()=>{
  //   const saveBookmark = new Parse.Query('partners_Subscriptions');
  //   const updateBookmark = await saveBookmark.get("wZmoDnBAk3");

  //   updateBookmark.set('IsDeleted', true);

  //   const res = await updateBookmark.save();
  // }
  componentDidMount() {
    this.currentTenattransDetails();
    //this.Delete()
  }

  render() {
    const { trnasactions, loading } = this.state;
    // order_id,
    // payment_id,
    // billing_id,
    // showModal,
    //function for grandtotal
    let grandTotal = 0;
    trnasactions.length > 0 &&
      trnasactions.forEach((inv, i) => {
        grandTotal += inv.attributes.TxnAmont;
      });

    const data = trnasactions.map((trns, ind) => {
      //Use data to specify the data that you want to display on table.
      return {
        packageName: (
          <div>
            <button type="button" className="btn btn-circle"></button>
            <span style={{ marginLeft: 0 }}>
              {" "}
              {trns.attributes.PlansPtr.attributes.PlanName}
            </span>
          </div>
        ),

        startDate:
          trns.get("TxnStatus") === "Failed"
            ? "-"
            : moment.utc(trns.attributes.FromDate).format("DD/MM/YYYY"),
        endDate:
          trns.get("TxnStatus") === "Failed"
            ? "-"
            : moment.utc(trns.attributes.ToDate).format("DD/MM/YYYY"),

        transDate: moment.utc(trns.attributes.TxnDate).format("DD/MM/YYYY"),
        amount: (
          <div>
            <img src="img/inr.png" alt="inr" /> {trns.get("TxnAmont")}
          </div>
        ),

        status: trns.get("TxnStatus") === "Success" ? "Successful" : "Failed",
        trdetails: (
          <button
            key={ind}
            onClick={() =>
              this.setState({
                showModal: true,
                order_id: trns.attributes.TxnId,
                payment_id: trns.attributes.Razorpay_payment_id,
                billing_id: trns.attributes.DefaultBillingCycle,
              })
            }
            className="btn btn-primary btn-sm"
          >
            View Deatils{" "}
          </button>
        ),

        trdetails2: trns.attributes.Razorpay_payment_id,
        trdetails6: trns.attributes.DefaultBillingCycle,
      };
    });
    const TableCellStyle = { borderRight: "1px solid #e5e5e5" };
    const option = {
      toolbar: false,
      showTitle: false,
      search: false,
      headerStyle: {
        border: "1px solid #e5e5e5",
        width: "20rem",
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        backgroundColor: "#edebeb",
      },
      rowStyle: {
        alignItem: "center",
        textAlign: "center",
        color: "black",
        borderRight: "1px solid #e5e5e5",
      },
    };
    //set column field name
    const columns = [
      {
        field: "packageName",
        title: "Package",
        cellStyle: TableCellStyle,
      },
      {
        field: "startDate",
        title: "Start-Date",
        cellStyle: TableCellStyle,
      },
      {
        field: "endDate",
        title: "End-Date",
        cellStyle: TableCellStyle,
      },
      {
        field: "transDate",
        title: "Transaction",
        cellStyle: TableCellStyle,
      },
      {
        field: "amount",
        title: "Amount",
        cellStyle: TableCellStyle,
      },
      {
        field: "status",
        title: "Status",
        cellStyle: TableCellStyle,
      },
      {
        field: "trdetails",
        title: "Details",
        cellStyle: TableCellStyle,
      },
    ];

    return (
      <>
        <div className="container">
          <div className="d-sm-flex align-items-center justify-content-between">
            <h1 className="h4 text-uvsh">
              Transactions - Qik Admin Console <br />
              <br />
            </h1>
          </div>
          <style dangerouslySetInnerHTML={{ __html: " \n\t" }} />

          {loading ? (
            <div className="d-flex justify-content-center">
              <Loading />
            </div>
          ) : (
            <MaterialTable
              columns={columns}
              data={data}
              options={option}
              components={{
                Body: (props) => (
                  <>
                    <MTableBody {...props} />
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={0} />
                        <TableCell colSpan={1} />
                        <TableCell />
                        <TableCell colSpan={1}>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "black",
                            }}
                          >
                            Grand Total
                          </span>
                        </TableCell>
                        <TableCell colSpan={4}>
                          <i
                            className="fa fa-inr rupeeicon"
                            style={{ fontWeight: "bold", fontSize: "15px" }}
                          >
                            &nbsp;{grandTotal}
                          </i>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </>
                ),
              }}
            />
          )}
        </div>

        <Modal show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title>Payment Details!</Modal.Title>

            <button
              type="button"
              className="close "
              onClick={() => {
                this.setState({ showModal: false });
              }}
            >
              <img src="img/close.png" alt="closeimage" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontWeight: "bold", marginRight: 20 }}>
              Order-Id:
            </span>
            {this.state.order_id}
            <hr />
            <span style={{ fontWeight: "bold", marginRight: 20 }}>
              Payment-Id:
            </span>
            {this.state.payment_id}
            <hr />
            <span style={{ fontWeight: "bold", marginRight: 20 }}>
              Billing Cycle:
            </span>
            {this.state.billing_id}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({ showModal: false });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
