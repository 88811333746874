
import React, { Component } from "react";
import "./UserProfile.css";
import "../Snackbar.css"
import Parse from "parse";
import { getAuthToken, imageUploadAPI } from "../utils/util";
import Axios from "axios";
import { emailValidator } from "../utils/validator";
import Loading from "./Loading";
export class UserProfile extends Component {
  constructor() {
    super();
    this.state = this.initialState;
  }
  initialState = {
    file: "",
    imagePreviewUrl: JSON.parse(localStorage.getItem("userPic")).src,
    active: "edit",
    nameError: "",
    emailError: "",
    phoneError: "",
    loading: false,
    disableSave: true,
    orgName:""
  };
  checkOrgName = async () => {
    try {
      const currentUser = await Parse.User.currentAsync();

      if (currentUser) {
        const orgQuery = new Parse.Query("orgApps");

        orgQuery.equalTo("superAdmin", currentUser);

        const res = await orgQuery.first();
        const result = JSON.parse(JSON.stringify(res));
        this.setState({orgName:result.appName})
         console.log("data", result.appName);
       
        
      }
    } catch (e) {
      console.log("orgError:", e);
    }
  };
 
  getTenantDetails = async () => {
    const subUser = Parse.Object.extend("partners_SubUsers"),
      querysubUser = new Parse.Query(subUser),
      user = Parse.User.current();
    querysubUser.equalTo("CreatedBy", user);
    querysubUser.include("TenantId");
    querysubUser.include("UserId");
    const subUserResult = await querysubUser.first();
    const Tenant = Parse.Object.extend("partners_Tenant"),
      query = new Parse.Query(Tenant);
    query.equalTo("CreatedBy", user); //current user point to the CreatedBy pointer.

    const tenantResult = await query.first();
  //  const res = JSON.parse(JSON.stringify(tenantResult));
    if (user && (tenantResult || subUserResult)) {
      this.setState({
        settings: tenantResult || subUserResult,
      });
    }
  };
  changeHandler = (event, settings, field) => {
    console.log("settings",settings)
    let value = event.target.value; //as per user types value will be updates.
    settings.set(field, value); //set field and field value.
    this.setState({ settings: settings, disableSave: false }); //update settings details
  };
  //file upload function
  fileUpload = async (file) => {
    const url = imageUploadAPI, //imageUploadAPI assign to the value of url and get value into local storage
      formData = new FormData(), //We want to send an image file as multipart/form to the backend.
      user = Parse.User.current(); //here we use current parse user.
    formData.append("file", file); //FormData allows us to append multiple key/value pairs onto the object.
    // After we’re done appending, we then pass it to the POST request’s body.
    //To add something at the end. =append
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "X-Parse-Application-Id": JSON.parse(localStorage.getItem("appId")),
        sessionToken: getAuthToken("auth_token"), //check proper authentication
      },
    };
    try {
      const imageUploadResponse = await Axios.post(url, formData, config); //
      console.log("imageUpload",imageUploadResponse)
      if (imageUploadResponse?.data?.status === "Success") {
        user.set("ProfilePic", imageUploadResponse?.data?.imageUrl);
        user.save();

        localStorage.setItem(
          "userPic",
          JSON.stringify({ src: imageUploadResponse?.data?.imageUrl })
        );
      } else {
        console.log("Error in image upload");
      }
    } catch (error) {
      alert("Error while setting profile Image", error);
    }
  };
  handleSubmit = async(e,settings) => {
    // const { settings } = this.state;
    console.log("settings handle",settings)
    if (settings) {
      if (!settings.get("TenantName")) {
        this.setState({ nameError: "TenantName is empty" });
      } else if (
        !settings.get("TenantName").length > 5 &&
        !settings.get("TenantName").length < 20
      ) {
        this.setState({
          nameError: "Name should be greater than 5 and less than 30 character",
        });
      } else if (!emailValidator.test(settings.get("EmailAddress"))) {
        this.setState({
          emailError: "Email must contain '@' symbol,Please enter valid email",
          nameError: "",
        });
      } else if (!/^\d{10}$/.test(settings.get("ContactNumber"))) {
        this.setState({
          phoneError: "Mobile No. should be 10 digit",
          emailError: "",
        });
      } else {
        this.setState(this.initialState); //
        this.setState({ loading: true });
        settings.save(); //after submit save profile details.
        e.preventDefault();
        // let activeP = this.state.active === "edit" ? "profile" : "edit"; //if active edit then execute profile otherwise execute edit.
        if (this.state.file) {
          this.fileUpload(this.state.file);
        } //upload file

        setTimeout(()=>{
         // this.myFunction("Profile updated successfully.");
          this.setState({ loading: false });
          this.getTenantDetails();
          this.checkOrgName()

        },1000)
          
 
      }
    }
  };
  componentDidMount() {

    this.getTenantDetails();
    this.checkOrgName()
    this.setState({
      imagePreviewUrl: JSON.parse(localStorage.getItem("userPic")).src,
    });
  }
  myFunction = (msg) => {
    console.log("msg",msg)
    var x = document.getElementById("snackbar");
    x.className = "show";
    x.innerHTML = msg;
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  render() {
    const { imagePreviewUrl, active, name, email, phone, settings, loading } =
      this.state;
      console.log("tenant name",settings)
    //ImgUpload is a functional component and we use here onChange ,scr are the props
    const ImgUpload = ({ onChange, src }) => (
      <label className="custom-file-upload fas">
           <div id="snackbar"></div>
        <div className="img-wrap img-upload">
          {/* {console.log("src",src)} */}
          {src ? (
            <img src={src} height="50px" width="40px" alt="img" />
          ) : (
            <i
              className="fa fa-user fa-sm fa-fw text-gray-400"
              style={{ fontSize: 40 }}
            />
          )}
        </div>
        <input id="photo-upload" type="file" onChange={onChange} />
      </label>
    );
    //submit profile pic using Profile as a functional component
    const Profile = ({ onSubmit }) => (
      <div className="profile-card profileborder">
        <form onSubmit={onSubmit}></form>
      </div>
    );

    //file photo upload function
    const photoUpload = (e) => {
      e.preventDefault();
      const reader = new FileReader(); //read file
      const file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
          disableSave: false,
        });
      };
      reader.readAsDataURL(file);
    };
    console.log("org name",this.state.orgName)
    return (
      <>
        <div>
          {active === "edit" ? (
            <div className="profile-card">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Loading />
                </div>
              ) : (
                <form>
                  <h4>Please upload Profile </h4>
                  <ImgUpload onChange={photoUpload} src={imagePreviewUrl} />
                  {settings && (
                    <div>
                      <div className="field">
                        <label>name:</label>
                        <input
                          id="name"
                          type="text"
                          name="name"
                          value={settings.get("TenantName")}
                          onChange={(e) =>
                            this.changeHandler(e, settings, "TenantName")
                          }
                          required
                        />
                      </div>
                      <div className="error-div">{this.state.nameError}</div>
                      <div className="field">
                        <label>email:</label>
                        <input
                          id="email"
                          name="email"
                          type="text"
                          value={settings.get("EmailAddress")}
                          onChange={(e) =>
                            this.changeHandler(e, settings, "EmailAddress")
                          }
                          required
                        />
                      </div>
                      <div className="error-div">{this.state.emailError}</div>
                      <div className="field" style={{ position: "relative" }}>
                        <label>phone:</label>
                        
                        <input
                          id="phone"
                          name="phone"
                          type="text"
                          value={settings.get("ContactNumber")}
                          onChange={(e) =>
                            this.changeHandler(e, settings, "ContactNumber")
                          }
                          required
                        />
                      </div>
                      <div className="error-div">{this.state.phoneError}</div>
                      <div className="field" style={{ position: "relative" }}>
                        <label>Organization Name:</label>
                        
                        <input
                          id="org"
                          name="org"
                          type="text"
                          value={this.state.orgName}
                          disabled={true}
                          required
                        />
                      </div>
                    </div>
                     
                  )}
                  <button
                    onClick={(e)=>this.handleSubmit(e,settings)}
                    className="btn btn-success mb-4"
                    type="submit"
                    disabled={this.state.disableSave}
                  >
                    Save
                  </button>
                </form>
              )}
            </div>
          ) : (
            <Profile
              onSubmit={this.handleSubmit}
              src={imagePreviewUrl}
              name={name}
              email={email}
              phone={phone}
            />
          )}
        </div>
      </>
    );
  }
}

