import { Home } from "../components/Home";
import { MyApplication } from "../components/MyApplication";
import { Subscriptionplan } from "../components/Subscriptionplan";
import { Users } from "../components/Users";
import { Transactions } from "../components/Transactions";
import { Settings } from "../components/Settings";
import { Help } from "../components/Help";
import { history } from "../services/history";
import { Notification } from "../components/Notification";

export const navRoutes = [
  {
    imgUrl: "img/m1.png",
    title: "Dashboard",
    path: "/Home",
    component: Home,
    role: ["admin", "accounts", "manager"],
  },
  {
    imgUrl: "img/m2.png",
    title: "Applications",
    component: MyApplication,
    // path: "/Applications",
    path:"/MyApplication",
    role: ["admin","manager"],
  },
  {
    imgUrl: "img/m3.png",
    title: "Transactions",
    path: "/Transactions",
    component: Transactions,
    role: ["admin", "accounts",],
  },
  {
    imgUrl: "img/m4.png",
    title: "Manage Users",
    path: "/Users",
    component: Users,
    role: ["admin", "manager"],
  },
  {
    imgUrl: "img/m5.png",
    title: "Subscription Plans",
    path: "/Subscriptionplan",
    component: Subscriptionplan,
    role: ["admin", "accounts",],
  },
  {
    imgUrl: "img/m5.png",
    title: "Resource Credits",
    path: "/Notification",
    component: Notification,
    role: ["admin", "accounts", "manager"],
  },
  {
    imgUrl: "img/m7.png",
    title: "Settings",
    path: "/Settings",
    component: Settings,
    role: ["admin", "accounts","manager" ],
  },
  {
    imgUrl: "img/m9.png",
    title: "Help Center",
    path: "/Help",
    component: Help,
    role: ["admin", "accounts","manager" ],
  },
];

export const routesByRole = (currentRole) => {
  if (["admin", "manager", "accounts"].some((r) => r === currentRole)) {
    return navRoutes.filter((r) => r.role.find((r) => r === currentRole)) || [];
  } else {
    // throw new Error(`Contact your admin to get access`);
    history.push("/login");
  }
};
