import React, { Component } from "react";
import Parse from "parse";
import "./Applications.css";
import "../Home.css";
import { getCurrentTenant } from "../utils/util";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import moment from "moment";
import axios from "axios";

export class MyApplication extends Component {
  constructor() {
    super();
    this.state = this.initialState;
  }
  initialState = {
    loading: true,
    application: [],
    isInstallApp: false,
    transactions: [],
    appName: "",
    installLoading: false,
    successInstall: false,
    maxApplication: "",
    installedApp: [],
    expiredPlan: false,
    alreadyExist: false,
    isPlan: true,
  };
  //for get current subscription
  currentsubscriptionsPlansDetails = async () => {
    let subscriptionsResult;
    try {
      const Tenant = Parse.Object.extend("partners_Tenant"), //
        tenantResult = getCurrentTenant(), //get current tenant result.
        tenantPointer = Tenant.createWithoutData(tenantResult.objectId), //create pointer by using createWithoutData methods.
        Subscriptions = Parse.Object.extend("partners_Subscriptions"),
        querySubscriptions = new Parse.Query(Subscriptions);
      querySubscriptions.include("PlansPtr");
      querySubscriptions.equalTo("TenantId", tenantPointer);
      querySubscriptions.equalTo("TxnStatus", "Success");
      querySubscriptions.notEqualTo("IsDeleted", true);
      querySubscriptions.descending("updatedAt");
      //find final result
      subscriptionsResult = await querySubscriptions.find();

      if (subscriptionsResult.length > 0) {
        const res = JSON.parse(JSON.stringify(subscriptionsResult));
        const application = res[0].PlansPtr.MaxApplication;
        const toDate = res[0].ToDate.iso;
        const updateToDate = new Date(toDate).getTime();
        const currDate = new Date().getTime();
        if (currDate > updateToDate) {
          this.setState({ expiredPlan: true });
        } else {
          this.setState({ expiredPlan: false, isValid: true });
        }

        this.setState({ maxApplication: application });

        //filter transactions using today date is greter than last date .
        const filtertrnasactions = subscriptionsResult.filter(
            (tr) =>
              new Date().getTime() <=
              new Date(`${tr.attributes.ToDate}`).getTime()
          ),
          //sort transactions using updated plans and find days left
          sortedTransactions = filtertrnasactions.sort(
            (a, b) => b.updatedAt - a.updatedAt //it take always last dated plan
          ),
          a = moment(sortedTransactions[0].attributes.ToDate),
          b = moment(sortedTransactions[0].attributes.FromDate),
          daysLeft = a.diff(b, "days");
        //check proper validation daysleft is always greter than zero.

        this.setState({
          transactions: [sortedTransactions[0]], //update transactions
          daysLeft: daysLeft, //update days left
          // loading: false,
        });

        //otherwise
      } else {
        this.setState({ isPlan: false });
      }
      this.applicationsDetails();
    } catch (e) {
      console.log("subscription error:", e);
    }
  };
  //for get installed application details
  installedApplications = async () => {
    try {
      const tenantResult = getCurrentTenant();

      const adminID = tenantResult.UserId.objectId;
      const orgQuery = new Parse.Query("orgApps");

      orgQuery.equalTo("superAdmin", {
        __type: "Pointer",
        className: "_User",
        objectId: adminID,
      });
      const res = await orgQuery.first();
      const result = JSON.parse(JSON.stringify(res));

      try {
        // const checkApp = await Parse.Cloud.run("checkInstalledApp", {
        //   orgName: result.appName,
        // });
        const parseAppId = "aprjnnhfcp";
        const serverUrl =
          "https://root.qik.ai/app/functions/checkInstalledApp";
        const data = {
          orgName: result.appName,
        };
      const res =  await axios
          .post(`${serverUrl}`, data, {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": parseAppId,
              sessiontoken :JSON.parse(localStorage.getItem("auth_token"))
            },
          })
      const checkApp = res.data.result;
        const count = checkApp.count;

        this.setState({
          installedApp: count,
        });
        return count;
      } catch (err) {
        console.log("installed app cloud function error", err);
      }
    } catch (e) {
      console.log("org app error", e);
    }
  };

  sortingApp = (appInfo, order) => {
    if (order === "asc") {
      return appInfo.sort((a, b) => (a.appname > b.appname ? 1 : -1));
    } else if (order === "desc") {
      return appInfo.sort((a, b) => (a.appname > b.appname ? -1 : 1));
    }
  };
  //get all app from default db
  applicationsDetails = async () => {
    await axios
      .get("https://server3.qik.ai/defaultssty/classes/w_appinfo", {
        headers: {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": "defaultssty",
        },
      })
      .then((Listdata) => {
        const json = Listdata.data;

        this.setState({ application: json.results });
        const strAscending = this.sortingApp(json.results, "asc");

        this.setState({
          application: strAscending,
          loading: false,
          appCount: json.results.length,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("axois err ", err);
      });
  };

  componentDidMount() {
    this.installedApplications();
    this.currentsubscriptionsPlansDetails();
  }
  //sort applications function
  sortApps = (e) => {
    const { application } = this.state, //extract state
      incAppsArray = application;
    if (e.target.value === "ASC") {
      this.sortingApp(incAppsArray, "asc");
    } else {
      //incAppsArray.sort((a,b)=>this.sortingApp(a,b,"desc"));
      this.sortingApp(incAppsArray, "desc");
    }
    this.setState({ application: incAppsArray });
  };

  //for install application
  installApp = async () => {
    this.setState({ installLoading: true, isInstallApp: false });
    const res = await this.installedApplications();

    if (res >= this.state.maxApplication) {
      this.setState({ installLoading: false });
    } else {
      try {
        const currentUser = await Parse.User.currentAsync();
        const user = JSON.parse(JSON.stringify(currentUser));
        if (user) {
          const tenantResult = getCurrentTenant();

          const adminID = tenantResult.UserId.objectId;
          const orgQuery = new Parse.Query("orgApps");

          orgQuery.equalTo("superAdmin", {
            __type: "Pointer",
            className: "_User",
            objectId: adminID,
          });

          const res = await orgQuery.first();
          const result = JSON.parse(JSON.stringify(res));

          // const funcRes = await Parse.Cloud.run("installApp", {
          //   appname: this.state.appName,
          //   organization: result.appName,
          //   UserId: user.objectId,
          // });

          const parseAppId = "aprjnnhfcp";
          const serverUrl =
            "https://root.qik.ai/app/functions/installApp";
          const data = {
              appname: this.state.appName,
            organization: result.appName,
            UserId: user.objectId,
          };
        const installRes =  await axios
            .post(`${serverUrl}`, data, {
              headers: {
                "Content-Type": "application/json",
                "X-Parse-Application-Id": parseAppId,
                sessiontoken :JSON.parse(localStorage.getItem("auth_token"))
              },
            })
            const funcRes = installRes.data.result;
          if (funcRes.message === "App is already exists") {
            this.setState({ alreadyExist: true, installLoading: false });
          }

          this.setState({ successInstall: true, installLoading: false });
        }
      } catch (e) {
        console.log("mirgation to admin cloud function error:", e);
        this.setState({ installLoading: false });
        alert("something went wrong!");
      }
    }
  };

  render() {
    const {
      application,
      loading,
      isInstallApp,
      transactions,
      installLoading,
      successInstall,
      installedApp,
      maxApplication,
      expiredPlan,
      alreadyExist,
      isPlan,
    } = this.state; //extract state

    return (
      <div>
        {installLoading && (
          <div className="install-loader">
            <div style={{ marginTop: "20%" }}>
              <Loading type="spin" />
            </div>
          </div>
        )}
        <div className="container-fluid">
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loading />
            </div>
          ) : (
            <div>
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 text-uvsh">
                  Applications- Qik Admin Console <br />
                </h1>
                <select
                  onChange={(e) => this.sortApps(e)} //sort applications using onchange handler
                  className="d-none form-control-uvs d-sm-inline-block"
                  defaultValue="ASC"
                >
                  <option value="ASC">AppName - Asc</option>
                  <option value="DESC">AppName - Desc</option>
                </select>
              </div>

              <div className="row ">
                {application ? (
                  application.map((app, index) => {
                    return (
                      <div key={index} className="col-md-3 col-sm-6 col-12">
                        <div className="card shadow mb-4">
                          <div className="card-body text-center">
                            <div style={{ height: "150px", marginTop: "30px" }}>
                              <img
                                src={app.applogo} //access AppLogo
                                alt={app.appname}
                                style={{
                                  width: "150px",
                                  height: "auto",
                                }}
                              />
                            </div>
                            <p style={{ fontWeight: "bold", color: "black" }}>
                              {app.appname}
                            </p>
                            <p style={{ fontSize: 15 }}>{app.appTitle}</p>

                            <button
                              onClick={() =>
                                this.setState({
                                  isInstallApp: true,
                                  appName: app.appname,
                                })
                              }
                              rel="noopener noreferrer"
                              className="btn btn-success"
                            >
                              Install
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    No data found!
                  </div>
                )}
              </div>
            </div>
          )}
          {/* for install application */}
          <Modal show={isInstallApp}>
            <Modal.Body>
              {isPlan ? (
                expiredPlan ? (
                  <p> your subscription plan has expired!</p>
                ) : installedApp >= maxApplication ? ( // checking maxApplication can be installed
                  <>
                    {transactions.length > 0 ? (
                      <p style={{ fontWeight: "bold" }}>
                        you have reached limit to install applications.
                      </p>
                    ) : (
                      "Please subscribe for install app!"
                    )}
                  </>
                ) : (
                  <>
                    {transactions.length > 0 ? (
                      <p>
                        <i className="fa fa-question-circle"></i> Are you sure
                        you want to install app ? <br />
                      </p>
                    ) : (
                      "Please subscribe for install app!"
                    )}
                  </>
                )
              ) : (
                <p>Please subscribe to install app</p>
              )}
            </Modal.Body>

            <Modal.Footer>
            {isPlan ?
              expiredPlan ? (
                <Link to="/Subscriptionplan">
                  <button type="button" class="btn btn-primary">
                    Subscribe
                  </button>
                </Link>
              ) : installedApp >= maxApplication ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({ isInstallApp: false });
                  }}
                >
                  Ok
                </button>
              ) : transactions.length > 0 && (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      this.setState({ isInstallApp: false });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      this.installApp();
                    }}
                    type="button"
                    className="btn btn-primary"
                  >
                    Install
                  </button>
                </>
              )  : (
                <Link to="/Subscriptionplan">
                  <button type="button" className="btn btn-primary">
                    Subscribe
                  </button>
                </Link>
              )}
            </Modal.Footer>
          </Modal>
          {/* for show success message of install app */}
          <Modal show={successInstall}>
            <Modal.Body>
              {alreadyExist ? (
                <p style={{ fontWeight: "bold" }}>
                  This app has already installed!
                </p>
              ) : (
                <p style={{ fontWeight: "bold" }}>Installation Successful!</p>
              )}
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  this.setState({
                    successInstall: false,
                    isInstallApp: false,
                  });
                }}
              >
                Ok
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}
