import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getUserRole, logoutUser } from "../utils/util";
import { routesByRole } from "../utils/userRoles";
import "../Navigation.css";
import { Modal } from "react-bootstrap";

export class Navigation extends Component {
  state = { navLinks: [], showModal: false };
  componentDidMount() {
    this.setState({ navLinks: routesByRole(getUserRole()) });
  }

  render() {
    return (
      <>
        <div style={{ background: "#0E1027" }}>
          <nav id="sidebaruv" style={{ height: "100vh" }}>
            <div className="sidebar-header">
              <button
                id="close-sidebar"
                className="d-block d-xs-none"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
              >
                <i
                  className="fa fa-times close-icon"
                  style={{ color: "white" }}
                ></i>
              </button>
              <Link
                className="sidebar-brand d-flex align-items-center"
                to="/Home"
              >
                <img
                  src="img/logoadmin.svg"
                  alt="adminlogo"
                  style={{ marginLeft: "-30px" }}
                />
              </Link>
            </div>
            <ul className="list-unstyled components">
              {/* <li className="nav-item" style={{ height: "50px" }}></li> */}
              {this.state.navLinks.map((route, index) => {
                return (
                  <li key={`${index}-${route.path}`}>
                    <Link to={route.path}>
                      <img src={route.imgUrl} className="mnuvs" alt={index} />
                      <span> {route.title}</span>
                    </Link>
                  </li>
                );
              })}

              <li className="logout-pointer">
                <div onClick={() => this.setState({ showModal: true })}>
                  <img src="img/m8.png" alt="logout" className="mnuvs" />
                  <span>Logout</span>
                </div>
              </li>
            </ul>
          </nav>
        </div>

        <Modal show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title>
              <i className="fa fa-sign-out" aria-hidden="true"></i>Log Out
            </Modal.Title>
            <button
              type="button"
              className="close "
              onClick={() => {
                this.setState({ showModal: false });
              }}
            >
              <img src="img/close.png" alt="closeimage" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <p>
              <i className="fa fa-question-circle"></i> Are you sure you want to
              log-off? <br />
            </p>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                this.setState({ showModal: false });
              }}
            >
              Close
            </button>
            <button
              onClick={logoutUser}
              type="button"
              className="btn btn-primary"
            >
              Log Out
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
