import React, { Component } from "react";
import "../Users.css";
import Parse from "parse";
import { getCurrentTenant, getSubUser, getUserRole } from "../utils/util";
import Modal from "react-bootstrap/Modal";
import Loading from "./Loading";
import "../Snackbar.css";
import MaterialTable from "@material-table/core";
import {
  emailValidator,
  phoneValidator,
  passwordValidator,
} from "../utils/validator";

export class Users extends Component {
  constructor() {
    super();
    this.state = this.initialState;
    this.onSave = this.onSave.bind(this);
  }
  initialState = {
    users: [],
    onShow: false,
    name: "",
    email: "",
    phone: "",
    pass: "",
    activeAction: "",
    isSubUserAdded: false,
    isSubUser: false, //initially subuser is false.
    userRoleValue: getUserRole() === "manager" ? "manager" : "accounts",
    userRole: getUserRole(),
    addUserModal: false,
    loading: true,
    deleteUser: false,
    isUserSave:false
  };
  //function for current tenant under subusers
  usersDetails = async () => {
    const Tenant = Parse.Object.extend("partners_Tenant"),
      tenantResult = getCurrentTenant(),
      tenantPointer = Tenant.createWithoutData(tenantResult.objectId),
      Subusers = Parse.Object.extend("partners_SubUsers"),
      query = new Parse.Query(Subusers);
    query.equalTo("TenantId", tenantPointer);
    query.equalTo("IsActive", true);
    query.descending("createdAt");
    const result = await query.find();
    if (result) {
      this.setState({ users: result, loading: false });
    }
  };
  onSave = async (e) => {
    e.preventDefault();
    this.setState({isUserSave:true})
    try {
      if (!this.state.name.trim().length > 0) {
        this.setState({ nameError: "Name should not be a blank", isUserSave:false});
      } else if (this.state.name.length > 50) {
        this.setState({ nameError: "Name should be less than 50 character",isUserSave:false });
      } else if (!emailValidator.test(this.state.email)) {
        this.setState({
          emailError: "Please enter valid email!",
          nameError: "",
          isUserSave:false
        });
      } else if (!phoneValidator.test(this.state.phone)) {
        this.setState({
          mobError: "Mobile No. should be 10 digit",
          emailError: "",
          isUserSave:false
        });
      } else if (!this.state.phone.match(/^(?=.*\d)(?=.*[1-9]).{1,10}$/)) {
        this.setState({
          mobError: "Should not accept all zeros",
          nameError: "",
          isUserSave:false
        });
      } else if (!passwordValidator.test(this.state.pass)) {
        this.setState({
          passError:
            "Password Must contain Uppercase letter['A-Z'],Lowercase letter['a-z'],digits['0-9'] and symbols['$@*&%#']",
          mobError: "",
          isUserSave:false
        });
      } else if (!this.state.userRoleValue) {
        this.setState({
          dropError: "Please select any Role",
          passError: "",
          isUserSave:false
        });
      } else {
        this.setState({
          nameError: "",
          mobError: "",
          emailError: "",
          passError: "",
          dropError: "",
          name: "",
          email: "",
          phone: "",
          pass: "",
          userRoleValue: "",
        });
        const { name, email, phone, pass, userRoleValue } = this.state;
        this.setState({addUserModal:false})
        const user = new Parse.User();

        user.set("name", name);
        user.set("phone", phone);
        user.set("username", email);
        user.set("email", email);
        user.set("password", pass);
        user.set("UserRole", userRoleValue);
        const userDetails = await user.save();

        if (userDetails) {
          this.registerSubuser(userDetails);
        }
        this.state({isUserSave:false})
      }
    } catch (error) {
      //console.log("error to add user", error.message);
      //  alert("Something went wrong, Please try again" + error);
      if (error.message === "Account already exists for this username.") {
        // console.log("aleary errror 47687678");
        alert("Account already exist !");
      }
    }
  };

  //register subuser function under current tenant
  registerSubuser = async (user) => {
    try {
      
      const Tenant = Parse.Object.extend("partners_Tenant"),
        tenantResult = getCurrentTenant(),
        tenantPointer = Tenant.createWithoutData(tenantResult.objectId),
        SubUsers = Parse.Object.extend("partners_SubUsers"),
        subUser = new SubUsers();
      subUser.set("TenantName", user.get("name"));
      subUser.set("EmailAddress", user.get("email"));
      subUser.set("CreatedBy", user);
      subUser.set("ContactNumber", user.get("phone"));
      subUser.set("IsActive", true);
      subUser.set("Password", user.get("password"));
      subUser.set("UserId", user);
      subUser.set("TenantId", tenantPointer);
      subUser.set("UserRole", user.get("UserRole"));
      const result = await subUser.save();

      if (result) {
        // this.usersDetails();
        // alert("Record Added Successfully");
        this.myFunction("Record Added Successfully");

        window.location.reload();
      }
    } catch (error) {
      alert("Something went wrong, Please try again" + error);
    }
  };
  onChangeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //show status activate and deactivated button using react-bootstrap-table
  cellButton = (cell, row) => {
    let theButton;
    theButton = (
      <>
        <button
          href="/#"
          type="button"
          data-toggle="modal"
          data-target="#myModal"
          className="btn btn-info infoclass"
          onClick={(e) => {
            this.setState({
              activeActionRecord: row, //
              activeAction: row.status.trim(), //status either activate or inactivate.
            });
          }}
        >
          {row.status}
        </button>
      </>
    );

    return theButton;
  };
  //show delete button using react-bootstrap-table

  deleteButton = (cell, row) => {
    let theButton;
    theButton = (
      <>
        <button
          href="/#"
          type="button"
          className="btn btn-danger dangerclass"
          onClick={(e) => {
            this.setState({
              activeActionRecord: row,
              activeAction: "DeActivate",
              deleteUser: true,
            });
          }}
        >
          <span></span>
          <i className="fa glyphicon glyphicon-trash fa-trash"></i>
        </button>
      </>
    );

    return theButton;
  };
  userAction = async (e, action, row) => {
    this.setState({ deleteUser: false });
    let response;
    const { user } = row;
    switch (action) {
      case "Activate":
        user.set("IsActive", true);
        response = await user.save();
        if (response) {
          this.usersDetails();
          setTimeout(() => {
            // alert("Record Activated Successfully");
            this.myFunction("Record Activated Successfully");
          }, 1000);
        }
        break;
      case "DeActivate":
        user.set("IsActive", false);
        response = await user.save();
        if (response) {
          this.usersDetails();
          setTimeout(() => {
            // alert("Record Deactivated Successfully");
            this.myFunction("Record Deactivated Successfully");
          }, 1000);
        }
        break;

      case "DELETE":
        response = await user.destroy();
        if (response) {
          this.usersDetails();
          setTimeout(() => {
            // alert("Record deleted successfully");
            this.myFunction("Record deleted successfully");
          }, 1000);
        }

        break;
      default:
        break;
    }
  };

  componentDidMount() {
    this.usersDetails();
    // this.addUserDetails();
    const subuser = getSubUser(); //get subuser
    this.setState({ isSubUser: subuser }); //update subuser
  }
  myFunction = (msg) => {
    var x = document.getElementById("snackbar");
    x.className = "show";
    x.innerHTML = msg;
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  render() {
    const {isUserSave} = this.state
    const option = {
      toolbar: false,
      showTitle: false,
      search: false,
      paging: false,
      headerStyle: {
        border: "1px solid #e5e5e5",
        width: "20rem",
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        backgroundColor: "#edebeb",
      },
      rowStyle: {
        alignItem: "center",
        textAlign: "center",
        color: "black",
        borderRight: "1px solid #e5e5e5",
      },
    };
    const TableCellStyle = { borderRight: "1px solid #e5e5e5" };
    const {
      users,
      name,
      email,
      phone,
      pass,
      userRoleValue,
      usersAdd,
      userRole,
      loading,
      
    } = this.state;

    // Use data to specify the data that you want to display on table.
    // console.log("users ", users[0]);
    const data = users.map((user, i) => {
      return {
        id: i,
        admin:
          user.attributes.UserId.attributes.name || user.attributes.TenantName,
        email: user.attributes.EmailAddress,
        lastLoginDate: user.attributes.updatedAt.toDateString(),
        status: user.get("IsActive") ? "Activate" : "DeActivate",
        user: user,
        role: user.attributes.UserRole,
      };
    });

    const column = [
      {
        field: "admin",
        title: "Name",
        cellStyle: TableCellStyle,
      },
      {
        field: "email",
        title: "Email Address",
        cellStyle: TableCellStyle,
      },
      {
        field: "lastLoginDate",
        title: "Created At",
        cellStyle: TableCellStyle,
      },
      {
        field: "role",
        title: "Role",
        cellStyle: TableCellStyle,
      },
      {
        field: "status",
        title: "Current Status",
        cellStyle: TableCellStyle,
      },
      {
        title: "Action",
        render: (rowData) => {
          const button = (
            <button
              type="button"
              className="btn btn-danger dangerclass"
              onClick={() =>
                this.setState({
                  activeActionRecord: rowData,
                  activeAction: "DeActivate",
                  deleteUser: true,
                })
              }
            >
              <i className="fa glyphicon glyphicon-trash fa-trash"></i>
            </button>
          );
          return button;
        },
      },
    ];

    return (
      <React.Fragment>
        
        <div className="container-fluid">
          <div id="snackbar"></div>
          {isUserSave && (
          <div
            className="install-loader"
            
          >
            <div style={{marginTop:"20%"}}>
            <Loading type="spin" />
          </div>
          </div>
        )}
          <div className="d-sm-flex align-items-center justify-content-between">
            <h1 className="h3 text-uvsh">
              Manage Users- Qik Admin Console <br />
            </h1>
            <button
              className="btn btn-primary btn-sm mb-4"
              onClick={() => this.setState({ addUserModal: true })}
            >
              Add New
            </button>
          </div>

          <style dangerouslySetInnerHTML={{ __html: "" }} />
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loading />
            </div>
          ) : (
            <div className="row uvsappss">
              <div className="col-lg-12 mb-4">
                <div className="card shadow mb-4">
                  <div className="table-responsive">
                    <MaterialTable
                      columns={column}
                      data={data}
                      options={option}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* for add users */}
        <Modal
          show={this.state.addUserModal}
          onHide={() => this.setState({ addUserModal: false })}
        >
          <Modal.Header>
            <Modal.Title>Add User</Modal.Title>
            <button
              type="button"
              className="close "
              onClick={() => {
                this.setState({ addUserModal: false });
              }}
            >
              <img src="img/close.png" alt="closeimage" />
            </button>
          </Modal.Header>
          <Modal.Body>
            {/* {isUserSave &&(
              <div style={{position:"absolute",opacity:3,zIndex:4}} className="d-flex justify-content-center">
              <Loading />
            </div>)} */}
             

            <form>
              <div className="row">
                <div className="col-md-6">
                  <span className="asterics">*</span> Name
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={name}
                    onChange={this.onChangeValue}
                  />
                  <div className="error-div">{this.state.nameError}</div>
                  <span className="asterics">*</span> Mobile Number
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={this.onChangeValue}
                  />
                  <div className="error-div">{this.state.mobError}</div>
                </div>
                <div className="col-md-6">
                  <span className="asterics">*</span> Email Address
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) =>
                      this.onChangeValue(e, usersAdd, "EmailAddress")
                    }
                    required
                  />
                  <div className="error-div">{this.state.emailError}</div>
                  <span className="asterics">*</span> Password
                  <input
                    type="password"
                    className="form-control"
                    id="pass"
                    name="pass"
                    value={pass}
                    onChange={this.onChangeValue}
                  />
                  <div className="error-div">{this.state.passError}</div>
                </div>
              </div>
              <div className="role">Role</div>
              <select
                onChange={this.onChangeValue}
                className="select-padding"
                name="userRoleValue"
                id="userRoleValue"
                value={userRoleValue}
                defaultValue={userRole === "manager" ? "manager" : "accounts"}
              >
                {userRole !== "manager" && (
                  <option value="accounts">Accounts</option>
                )}
                <option value="manager">Manager</option>
              </select>
              {/* {userRole !== "manager" && (
                <div className="error-div">{this.state.dropError}</div>
              )} */}
              <div className="row">
                <div className="col-md-6 col-6">
                  <button
                    className="btn btn-primary"
                    id="savelink"
                    onClick={(e) => this.onSave(e)}
                  >
                    Save &nbsp;
                  </button>
                </div>
              </div>
            </form>
            
          </Modal.Body>
        </Modal>

        {/* for delete users */}
        <Modal
          show={this.state.deleteUser}
          onHide={() => this.setState({ deleteUser: false })}
        >
          <Modal.Header>
            <Modal.Title>Are you sure?</Modal.Title>
            <button
              type="button"
              className="close "
              onClick={() => {
                this.setState({ deleteUser: false });
              }}
            >
              <img src="img/close.png" alt="closeimage" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <p>
              Do you really want to {this.state.activeAction} these records?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                this.setState({ deleteUser: false });
              }}
            >
              Cancel
            </button>
            <button
              onClick={(e) =>
                this.userAction(
                  e,
                  this.state.activeAction,
                  this.state.activeActionRecord
                )
              }
              type="button"
              className="btn btn-danger"
            >
              {this.state.activeAction}
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
