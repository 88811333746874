import React, { Component } from "react";
import "../Settings.css";
import Parse from "parse";
import Loading from "./Loading";
import { emailValidator } from "../utils/validator";
import { setCurrentTenant } from "../utils/util";
export class Settings extends Component {
  constructor() {
    super();
    this.state = this.initialState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTenantDetails = this.getTenantDetails.bind(this);
  }
  initialState = {
    nameError: "",
    emailError: "",
    businessError: "",
    businessTypeError: "",
    phoneError: "",
    adressError: "",
    stateError: "",
    countryError: "",
    pinError: "",
    loading: true,
    disabled: true,
  };
  //function handle submit on save
  handleSubmit = async (e, settings) => {
    e.preventDefault();
    this.setState({ disabled: false });
    if (!this.state.disabled) {
      if (!settings.get("TenantName")) {
        this.setState({ nameError: "TenantName is empty" });
      } else if (settings.get("TenantName").length > 50) {
        this.setState({ nameError: "Name should be less than 50 character" });
      } else if (!emailValidator.test(settings.get("EmailAddress"))) {
        this.setState({
          emailError: "Email must contain '@' symbol,Please enter valid email",
          nameError: "",
        });
        // } else if (settings.get("BusinessName").length > 50) {
        //   this.setState({
        //     businessError: "BUsiness Name should be less than 50 character",
        //     emailError: "",
        //   });
        // } else if (settings.get("BusinessType").length > 50) {
        //   this.setState({
        //     businessTypeError: "BusinessType should be less than 50 character",
        //     businessError: "",
        //   });
      } else if (!/^\d{10}$/.test(settings.get("ContactNumber"))) {
        this.setState({
          phoneError: "Mobile No. should be 10 digit",
          businessTypeError: "",
        });
      } else if (
        !/^(?=.*\d)(?=.*[1-9]).{1,10}$/.test(settings.get("ContactNumber"))
      ) {
        this.setState({
          mobError: "Should not accept all zeros",
          nameError: "",
        });
        // } else if (settings.get("Address").length > 150) {
        //   this.setState({
        //     adressError: "Address name should be less than 150 character",
        //     phoneError: "",
        //   });
        // } else if (settings.get("State").length > 20) {
        //   this.setState({
        //     stateError: "State name should be less than 20 character",
        //     adressError: "",
        //   });
        // } else if (settings.get("Country").length > 20) {
        //   this.setState({
        //     countryError: "Country name should be less than 20 character",
        //     stateError: "",
        //   });
      } else if ((!settings.get("City")).length > 20) {
        this.setState({
          cityError: "City name should be less than 20 character",
          pinError: "",
        });
      } else {
        this.setState(this.initialState);
        try {
          await settings.save();
          this.getTenantDetails();
        } catch (e) {
          console.log("error", e);
        }
      }
      this.myFunction("Personal information updated successfully.");
    }
  };

  changeHandler = (event, settings, field) => {
    let value = event.target.value;
    settings.set(field, value);
    this.setState({ settings: settings });
  };
  //current tenant detail function
  getTenantDetails = async () => {
    const subUser = Parse.Object.extend("partners_SubUsers"),
      querysubUser = new Parse.Query(subUser),
      user = Parse.User.current();
    querysubUser.equalTo("UserId", user);
    querysubUser.include("TenantId");
    const subUserResult = await querysubUser.first();
    const Tenant = Parse.Object.extend("partners_Tenant"),
      query = new Parse.Query(Tenant);
    query.equalTo("CreatedBy", user);
    // this.setState({ loading: true });
    const tenantResult = await query.first();
    if (user && (tenantResult || subUserResult)) {
      setCurrentTenant(
        tenantResult ? tenantResult : subUserResult.attributes.TenantId
      );
      this.setState({
        settings: tenantResult || subUserResult,
        loading: false,
      });
    }
  };
  componentDidMount() {
    this.getTenantDetails();
  }
  myFunction = (msg) => {
    var x = document.getElementById("snackbar");
    x.className = "show";
    x.innerHTML = msg;
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  render() {
    const { settings, disabled, loading } = this.state;

    return (
      <div className="container-fluid">
        <div id="snackbar"></div>
        <div className="d-sm-flex align-items-center justify-content-between">
          <h1 className="h3 text-uvsh">
           
            <small>Organization Information</small>
          </h1>
          <button
            onClick={(e) => {
              this.setState({ disabled: false });
            }}
            className="btn btn-primary btn-sm mb-4"
          >
            Edit
          </button>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        ) : (
          settings && (
            <div className="row uvsappss">
              <div className="col-lg-12 mb-4">
                <div className="card shadow mb-4 uvsbtm">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <span className="asterics">*</span> Contact Person
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={settings.get("TenantName")}
                        onChange={(e) =>
                          this.changeHandler(e, settings, "TenantName")
                        }
                        required
                        disabled={disabled}
                      />
                      <div className="error-div">{this.state.nameError}</div>
                      <span className="asterics">*</span> Email Address
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        value={settings.get("EmailAddress")}
                        onChange={(e) =>
                          this.changeHandler(e, settings, "EmailAddress")
                        }
                        required
                        disabled={disabled}
                      />
                      <div className="error-div">{this.state.emailError}</div>
                      Business Name
                      <input
                        type="text"
                        className="form-control"
                        id="bussiname"
                        name="bussiname"
                        value={settings.get("BusinessName")}
                        onChange={(e) =>
                          this.changeHandler(e, settings, "BusinessName")
                        }
                        required
                        disabled={disabled}
                      />
                      <div className="error-div">
                        {this.state.businessError}
                      </div>
                      Business Type
                      <input
                        type="text"
                        className="form-control"
                        id="bussinesstype"
                        name="bussinesstype"
                        value={settings.get("BusinessType")}
                        onChange={(e) =>
                          this.changeHandler(e, settings, "BusinessType")
                        }
                        required
                        disabled={disabled}
                      />
                      <div className="error-div">
                        {this.state.businessTypeError}
                      </div>
                      <div style={{ position: "relative" }}>
                        <span className="asterics">*</span> Contact Number
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                          value={settings.get("ContactNumber")}
                          onChange={(e) =>
                            this.changeHandler(e, settings, "ContactNumber")
                          }
                          required
                          disabled={disabled}
                        />
                        <div className="error-div">{this.state.phoneError}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      Address
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        value={settings.get("Address")}
                        onChange={(e) =>
                          this.changeHandler(e, settings, "Address")
                        }
                        required
                        disabled={disabled}
                      />
                      <div className="error-div">{this.state.adressError}</div>
                      State
                      <input
                        type="text"
                        className="form-control"
                        id="state"
                        name="state"
                        value={settings.get("State")}
                        onChange={(e) =>
                          this.changeHandler(e, settings, "State")
                        }
                        required
                        disabled={disabled}
                      />
                      <div className="error-div">{this.state.stateError}</div>
                      Country
                      <input
                        type="text"
                        className="form-control"
                        id="country"
                        name="country"
                        value={settings.get("Country")}
                        onChange={(e) =>
                          this.changeHandler(e, settings, "Country")
                        }
                        required
                        disabled={disabled}
                      />
                      <div className="error-div">{this.state.countryError}</div>
                      PinCode
                      <input
                        type="text"
                        className="form-control"
                        id="pincode"
                        name="pincode"
                        value={settings.get("PinCode")}
                        onChange={(e) =>
                          this.changeHandler(e, settings, "PinCode")
                        }
                        required
                        disabled={disabled}
                      />
                      <div className="error-div">{this.state.pinError}</div>
                      City
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        name="city"
                        value={settings.get("City")}
                        onChange={(e) =>
                          this.changeHandler(e, settings, "City")
                        }
                        required
                        disabled={disabled}
                      />
                      <div className="error-div">{this.state.cityError}</div>
                    </div>
                    {!this.state.disabled && (
                      <div className="col-md-12 text-center ">
                        <button
                          onClick={(e) => this.handleSubmit(e, settings)}
                          className="btn btn-success mb-4"
                        >
                          Save
                        </button>{" "}
                        <button
                          onClick={() => this.setState({ disabled: true })}
                          className="btn btn-danger mb-4"
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}
