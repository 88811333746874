import React, { Component } from "react";
import "../plans.css";
import { Modal, Button } from "react-bootstrap";
import Parse from "parse";
import axios from "axios";
import Loading from "./Loading";
import "../Snackbar.css";
import {
  getCurrentTenant,
  getExpiryPlans,
  getHasplanPurchase,
  setExpiryPlans,
  setHasplanPurchase,
  saveTransaction,
  getSubUser,
  getUserRole,
} from "../utils/util";
import moment from "moment";
import { history } from "../services/history";
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
// const __DEV__ = document.domain === "localhost"; //show the current domain name
export class Plans extends Component {
  constructor() {
    super();
    this.state = this.initialState;
  }
  initialState = {
    modalData: {}, //for more button modal.this is object type state.
    loading: false, //loading initially false
    planNames: ["Basic Plan", "Silver Plan", "Gold Plan", "Platinum Plan"], //this is array type state.
    availablePlans: [], //this is array type state.use for avail plan
    BasicPlan: {}, //this is object type state use for avail basic plan changes
    SilverPlan: {}, //this is object type state avail silver plan changes
    GoldPlan: {}, //this is object type state avail gold plan changes
    PlatinumPlan: {}, //this is object type state avail platinum plan changes
    BasicPlanc: {}, //this is object type state use for current basic plan changes
    SilverPlanc: {}, //this is object type state use for current silver plan changes
    GoldPlanc: {}, //this is object type state use for current gold plan changes
    PlatinumPlanc: {}, //this is object type state use for current platinum plan changes
    isPlanExpired: false, //this is string type state
    paymentLoading: false, //this is boolean type state.paymentLoading initially false.
    isSubUser: false, //this is boolean type state.isSubUser initially false.
    plans: [], // to store current plan details
    PlanPrice: 0,
    showModal: false,
    showPayModal: false,
    currentPtr: "",
    currAmount: "",
    curr_billing: "",
    curr_daysLeft: "",
    subscribeModal: false,
    modalKey: "",
    selectedPlanName: "",
    reActiveModal: false,
    activePlan: [],
    UserRole: ["admin", "manager", "accounts"],
    totalPrice: null,
  };
  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();
    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }
  // This function is to load razorpay checkout script, display payment modal and initiate payment process
  displayRazorpay = async (
    selectedPlan, //selected plan
    amount, //selected plan amount
    _billing, //billing cycle
    existingPlanAmount = 0, //existingPlanAmount
    mode,
    currentPlan
  ) => {
    this.setState({ subscribeModal: false });
    let toDate;

    if (mode === "extend") {
      toDate = currentPlan.attributes.ToDate;
    }
    // console.log(
    //   mode,
    //   "selectedPlan",
    //   selectedPlan,
    //   amount,
    //   _billing,
    //   existingPlanAmount,
    //   currentPlan
    // );

    const remainingPlanAmount = existingPlanAmount ? existingPlanAmount : 0;
    //check validation if amount is less than existingPlanAmount then trow error

    if (amount < this.state.totalPrice && mode === "switch") {
      this.myFunction(
        "You can not Buy the lower-valued plan, you can switch higher valued plan"
      );

      // alert(
      //   "You can not Buy the lower-valued plan, you can switch higher valued plan"
      // );

      return;
    }
    let remainPrice;
    if (mode === "switch") {
      remainPrice = this.state.totalPrice;
    }

    amount = remainPrice ? amount - remainPrice : amount;
    console.log("amount", amount, remainPrice);

    this.setState({ paymentLoading: true });
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      this.setState({ paymentLoading: false });
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    //This cloud function is use to create order and return order details
    const order = await Parse.Cloud.run("CreateOrder", {
      amount: amount,
      currency: "INR",
      env: "dev", //__DEV__ ? "dev" : "prod",
    });
    console.log("order", order, amount);
    // Options to proceed payment details
    const curretTenant = getCurrentTenant();
    const { currentsubscriptionsPlansDetails } = this;
    const { subscriptionsDetails } = this;
    //here we use saveTransaction is a callback function. so callback function don't know keyword about this.
    //we have to-already extract into it "this" keyword.
    const options = {
      key: "rzp_test_JuKUtrnG9SoyHk", // __DEV__ ? "rzp_test_JuKUtrnG9SoyHk" : "rzp_live_wtUytXxTyUkjds",
      currency: order.currency,
      amount: order.amount,
      order_id: order.id,
      name: "Qik.Ai",
      // image: "http://localhost:3000/logo.svg",
      //function for success payment
      //handler is a callback function .it execute last
      handler: async (response) => {
        //If the payment is successful then its return the following fields.
        const { razorpay_signature, razorpay_payment_id, razorpay_order_id } =
          response;
        //this is payment capture api run here
        //capture payment either success or failed
        const captureResponse = await Parse.Cloud.run("paymentCapture", {
          razorpay_payment_id,
          razorpay_signature,
          razorpay_order_id,
          amount,
          currency: "INR",
          env: "dev", //__DEV__ ? "dev" : "prod",
        });
        //function for save successful transaction
        const { items } = captureResponse;

        if (items.length > 0) {
          const result = await saveTransaction(
            items, //payment details
            _billing, //duration
            amount, //selected plan amount
            selectedPlan, //selected plan
            razorpay_payment_id, //
            razorpay_order_id, //

            toDate
          );
          if (result) {
            // alert("Transaction done successfully. ");

            this.myFunction("Transaction done successfully.");
            //for migrate admin data from base db to client db
            try {
              const currentUser = await Parse.User.currentAsync();
              const user = JSON.parse(JSON.stringify(currentUser));
              if (user) {
                const orgQuery = new Parse.Query("orgApps");

                orgQuery.equalTo("superAdmin", currentUser);

                const res = await orgQuery.first();
                const result = JSON.parse(JSON.stringify(res));

                const parseAppId = "aprjnnhfcp";
                const serverUrl =
                  "https://root.qik.ai/app/functions/setupAppcreator";
                const data = {
                  orgApps: {
                    orgName: result.appName,
                  },
                  user: {
                    username: user.email,
                    name: user.name,
                    phone: user.phone,
                    objectId: user.objectId,
                  },
                };
                await axios
                  .post(`${serverUrl}`, data, {
                    headers: {
                      "Content-Type": "application/json",
                      "X-Parse-Application-Id": parseAppId,
                      sessiontoken :localStorage.getItem("auth_token")
                    },
                  })

                  .then((Listdata) => {
                    console.log("Listdata ", Listdata);
                    const json = Listdata.data.results;
                    console.log("json ", json);
                    return json;
                  })
                  .catch((err) => {
                    console.log("axois err ", err);
                  });
              }
            } catch (e) {
              console.log("mirgation to admin cloud function error:", e);
            }
            setHasplanPurchase(true); //plan is not purchase.
            setExpiryPlans(false); //plan expired.
            currentsubscriptionsPlansDetails(); //current plans details
            subscriptionsDetails(); //subscriptions details
          }
        }
      },
      //function for save  failure transactions on modal dismiss.
      modal: {
        escape: false,
        ondismiss: async () => {
          const captureResponse = await Parse.Cloud.run("paymentCapture", {
            razorpay_payment_id: null,
            razorpay_signature: null,
            razorpay_order_id: order.id,
            amount: remainingPlanAmount ? amount - remainingPlanAmount : amount,
            currency: "INR",
            env: "dev", // __DEV__ ? "dev" : "prod",
          });
          const { items } = captureResponse;
          if (items.length > 0) {
            const result = await saveTransaction(
              items, //its contains payment details
              _billing, //selected billing cycle
              amount, //selected plan amount
              selectedPlan, //selected plan name
              null, //payment id is null because its capture failed transaction
              null //order id is null because its capture failed transaction
            );
            if (result) {
              // alert("Transaction failed. ");
              this.myFunction("Transaction failed. ");
              currentsubscriptionsPlansDetails(); //refetch current subscriptions details
              subscriptionsDetails(); //refetch subscriptions details
            }
          }
        },
        onclose: async () => {}, //this is method of close module
      },
      prefill: {
        //Cardholders name to be pre-filled when the Checkout opens.
        name: curretTenant.TenantName,
        email: curretTenant.EmailAddress,
        contact: curretTenant.ContactNumber,
      },
    };
    //show iframe overlay in modal format
    this.setState({ paymentLoading: false }); //after payment capture loading false
    const paymentObject = new window.Razorpay(options); //its take options as a parameter.
    paymentObject.open(); //ake options and open modal
  };
  //function for avail  subscriptions plans details
  subscriptionsDetails = async () => {
    const isPlanPerchased = getHasplanPurchase();
    if (!isPlanPerchased) {
      history.push("/Subscriptionplan");
    } else {
      const Plans = Parse.Object.extend("partners_Plans");
      const queryPlans = new Parse.Query(Plans);

      this.setState({ loading: true });
      const planResult = await queryPlans.find();
      console.log("plans", planResult);

      if (planResult) {
        let sortedPlans = [];
        // array.forEach() method iterates over the array items, in ascending order, without mutating the array.
        //The first argument of forEach() is the callback function called for every item in the array.
        //plan Name Basic,Silver,Gold,Platinum ascending order plan
        this.state.planNames.forEach((p) => {
          // find() methods does not change the original array
          sortedPlans.push(planResult.find((a) => a.attributes.PlanName === p));
        });

        //filter current plan and subscriptions plans,doesn't repeat it again.
        if (this.state.plans.length > 0) {
          //filter() does not change the original array.

          sortedPlans = sortedPlans.filter(
            (sp) => sp.id !== this.state.plans[0].get("PlansPtr").id
          );
        }

        this.setState({ availablePlans: sortedPlans, loading: false });
      }
    }
  };

  //function for on change price details
  onChangePlanPrice = (e, planArray) => {
    let valArr = e.target.value.split("-");

    const activePlanObj = planArray.find((p) => p.duration === valArr[1]);

    this.setState({
      [valArr[0].replace(/ /g, "")]: activePlanObj,
    });
  };

  //function for current subscriptions plans details
  currentsubscriptionsPlansDetails = async () => {
    const Tenant = Parse.Object.extend("partners_Tenant"), //
      tenantResult = getCurrentTenant(), //get current tenant result.
      tenantPointer = Tenant.createWithoutData(tenantResult.objectId), //create pointer by using createWithoutData methods.
      Subscriptions = Parse.Object.extend("partners_Subscriptions"),
      querySubscriptions = new Parse.Query(Subscriptions);
    querySubscriptions.include("PlansPtr");
    querySubscriptions.equalTo("TenantId", tenantPointer);
    querySubscriptions.equalTo("TxnStatus", "Success");
    querySubscriptions.notEqualTo("IsDeleted", true);
    const subscriptionsResult = await querySubscriptions.find();

    // const endUpdateDate = new Date().getTime();
    // const newDate = new Date().getTime();

    if (subscriptionsResult.length > 0) {
      setHasplanPurchase(true); //plan Purchase
      // const activeTrnasactions = subscriptionsResult.filter(
      //   (tr,i) =>
      //     new Date().getTime() <= new Date(`${tr.attributes.ToDate}`).getTime()
      // );
      // const updateActiveTr = activeTrnasactions.filter(
      //   (tr,i) =>

      // );
      const filtertrnasactions = subscriptionsResult.filter(
          (tr) =>
            new Date().getTime() <=
            new Date(`${tr.attributes.ToDate}`).getTime()
        ),
        sortedTransactions = filtertrnasactions.sort(
          (a, b) => b.updatedAt - a.updatedAt //it take always last dated plan
        ),
        // a = moment(sortedTransactions[0].attributes.ToDate),
        // b = moment(sortedTransactions[0].attributes.FromDate),
        oneDay = 24 * 60 * 60 * 1000;
      const daysLeft = Math.floor(
        (sortedTransactions[0].attributes.ToDate - new Date()) / oneDay
      );
      const activeTrnasactionss =
        sortedTransactions[0].attributes.PlansPtr.attributes.PlanName;
      const updateActivePlan = sortedTransactions.filter(
        (data, i) =>
          data.attributes.PlansPtr.attributes.PlanName === activeTrnasactionss
      );

      // console.log("active plan name",updateActivePlan)
      // const daysLeft = daysLeft1.toFixed(0)
      // daysLeft = a.diff(b, "days");
      //get active plan left days
      const currLeftDays = Math.floor(
        (updateActivePlan[updateActivePlan.length - 1].attributes.ToDate -
          new Date()) /
          oneDay
      );
      //calculate txn amount
      const txnAmount =
        updateActivePlan[updateActivePlan.length - 1].attributes.TxnAmont;
      //billing cycle "1year",'1month
      const billingCycle =
        updateActivePlan[updateActivePlan.length - 1].attributes
          .DefaultBillingCycle;
      //get billingCycle in '1 Year','1 Month' format just trim it to take how many month and month or year
      //number of month or year
      let billingDay = billingCycle.slice(0, 1);
      //get subscription unit in year or month
      let billingDays = billingCycle.substring(2);
      let aYear, aMonth, aDay, remainPrice;
      if (billingDays === "Years" || billingDays === "Year") {
        if (billingDay > 1) {
          aYear = txnAmount / billingDay;

          aMonth = aYear / 12;
          aDay = (aMonth / 30).toFixed(1);

          remainPrice = (currLeftDays * aDay).toFixed(0);
        } else if (billingDay === 1) {
          aMonth = txnAmount / 12;
          aDay = aMonth / 30;
          remainPrice = (currLeftDays * aDay).toFixed(0);
        }
      } else if (billingDays === "Month" || billingDays === "Months") {
        aDay = txnAmount / (billingDay * 30);

        remainPrice = (currLeftDays * aDay).toFixed(0);
      }
      let remainPriceInt = parseInt(remainPrice);

      //for adding all active extended plan amount
      for (let i = 0; i <= updateActivePlan.length - 2; i++) {
        remainPriceInt += updateActivePlan[i].attributes.TxnAmont;
      }

      // console.log(
      //   // totalAmount,
      //   "daysleft",
      //   currLeftDays,
      //   txnAmount,
      //   billingCycle,
      //   billingDay,
      //   billingDays,
      //   remainPrice,
      //   aDay,
      //   remainPriceInt
      // );
      if (daysLeft > 0) {
        setExpiryPlans(false); //plan is  expired
      } else {
        setExpiryPlans(true); //plan is not expired
      }
      const getexpiriPlan = getExpiryPlans();

      this.setState({
        plans: [sortedTransactions[0]], //it show only one plan as a current plan.
        isPlanExpired: getexpiriPlan,
        daysLeft: daysLeft,
        totalPrice: remainPriceInt,
      });
    } else {
      setHasplanPurchase(false); //plan is not purchase.
      setExpiryPlans(true); //plan expired.
    }
  };
  //componentDidMount is executed after the first render only on the client side
  componentDidMount = async () => {
    await this.currentsubscriptionsPlansDetails(); //
    await this.subscriptionsDetails(); //
    const subuser = getSubUser();
    this.setState({ isSubUser: subuser });
    const userrole = getUserRole();

    this.setState({
      UserRole: userrole,
    });
  };
  myFunction = (msg) => {
    var x = document.getElementById("snackbar");
    x.className = "show";
    x.innerHTML = msg;
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  render() {
    let remainingPlanAmount = 0; //remainingPlanAmount initially should be zero

    const {
      resultFound,
      availablePlans,
      modalData,
      loading,
      plans,
      isPlanExpired,
      paymentLoading,

      subscribeModal,
      modalKey,
      UserRole,
    } = this.state;

    const PlanAdvantageModal = (props) => {
      const { data } = props;

      return (
        <Modal show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title>
              {" "}
              {Object.entries(data).length > 0 && data?.name} Feature
            </Modal.Title>

            <button
              type="button"
              className="close "
              onClick={() => {
                this.setState({ showModal: false });
              }}
            >
              <img src="img/close.png" alt="closeimage" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <ul className="uvsplul">
                  {/* The entries() method returns an Array Iterator object with key/value pairs. */}
                  {Object.entries(data).length > 0 &&
                    data?.features.map((planAdvantage, key) => {
                      return (
                        <li key={key}>
                          <button className="btn btn-warning  btn-circle btn-uvs"></button>
                          {planAdvantage}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({ showModal: false });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    };

    const expiredDate = (currPlan) => {
      const date = moment.utc(currPlan.attributes.ToDate).format("DD/MM/YYYY");
      return date;
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div id="snackbar"></div>
          <h3 className="h3 text-uvsh mb-4">Qik Admin Console -</h3>
          {paymentLoading && (
            <div
              className="payment-loader"
              style={{
                margin: "0 auto",
                position: "absolute",
                height: "100vh",
                width: "100%",
              }}
            >
              <Loading type="spin" />
            </div>
          )}
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loading />
            </div>
          ) : (
            <div className="row uvsappss">
              {plans && plans.length > 0
                ? plans.map((currentPlan, key) => {
                    let plansArr = [];
                    let extendDiscount, extendPrice, extendPlan;

                    for (let i in currentPlan.attributes.PlansPtr.attributes) {
                      let obj = {};

                      switch (i) {
                        case "Price1Mo":
                          obj["duration"] = "1 Month";
                          obj["actualPrice"] =
                            currentPlan.attributes.PlansPtr.attributes[i];
                          obj["discountPrice"] =
                            currentPlan.attributes.PlansPtr.attributes.Mrp1Mo;
                          obj["features"] = [];
                          obj["isDefault"] =
                            currentPlan.attributes.PlansPtr.attributes
                              .DefaultBillingCycle === "1 Month";
                          plansArr.push(obj);
                          break;
                        case "Price3Mo":
                          obj["duration"] = "3 Months";
                          obj["actualPrice"] =
                            currentPlan.attributes.PlansPtr.attributes[i];
                          obj["discountPrice"] =
                            currentPlan.attributes.PlansPtr.attributes.Mrp3Mo;
                          obj["features"] = [];
                          obj["isDefault"] =
                            currentPlan.attributes.PlansPtr.attributes
                              .DefaultBillingCycle === "3 Months";
                          plansArr.push(obj);
                          break;
                        case "Price6Mo":
                          obj["duration"] = "6 Months";
                          obj["actualPrice"] =
                            currentPlan.attributes.PlansPtr.attributes[i];
                          obj["discountPrice"] =
                            currentPlan.attributes.PlansPtr.attributes.Mrp6Mo;
                          obj["features"] = [];
                          obj["isDefault"] =
                            currentPlan.attributes.PlansPtr.attributes
                              .DefaultBillingCycle === "6 Months";
                          plansArr.push(obj);
                          break;
                        case "Price12Mo":
                          obj["duration"] = "1 Year";
                          obj["actualPrice"] =
                            currentPlan.attributes.PlansPtr.attributes[i];
                          obj["discountPrice"] =
                            currentPlan.attributes.PlansPtr.attributes.Mrp12Mo;
                          obj["features"] = [];
                          obj["isDefault"] =
                            currentPlan.attributes.PlansPtr.attributes
                              .DefaultBillingCycle === "1 Year";
                          plansArr.push(obj);
                          break;
                        case "Price36Mo":
                          obj["duration"] = "3 Years";
                          obj["actualPrice"] =
                            currentPlan.attributes.PlansPtr.attributes[i];
                          obj["discountPrice"] =
                            currentPlan.attributes.PlansPtr.attributes.Mrp36Mo;
                          obj["features"] = [];
                          obj["isDefault"] =
                            currentPlan.attributes.PlansPtr.attributes
                              .DefaultBillingCycle === "3 Years";
                          plansArr.push(obj);
                          break;
                        case "Price60Mo":
                          obj["duration"] = "5 Years";
                          obj["actualPrice"] =
                            currentPlan.attributes.PlansPtr.attributes[i];
                          obj["discountPrice"] =
                            currentPlan.attributes.PlansPtr.attributes.Mrp60Mo;
                          obj["features"] = [];
                          obj["isDefault"] =
                            currentPlan.attributes.PlansPtr.attributes
                              .DefaultBillingCycle === "5 Years";
                          plansArr.push(obj);
                          break;
                        default:
                          break;
                      }
                    }

                    let backgroundImage = {
                      background: "",
                      backgroundSize: "100% 100%",
                    };
                    const {
                      BasicPlanc,
                      SilverPlanc,
                      GoldPlanc,
                      PlatinumPlanc,
                     
                    } = this.state;
                    let discountPrice,
                      actualPrice,
                      amount,
                      billingCycle,
                      defualt,
                      // availblePlanPrice,
                      PlanPrice;

                    let buttonBackground = {
                      background: "",
                      color: "",
                    };

                    if (
                      currentPlan.attributes.PlansPtr.attributes.PlanName ===
                      "Gold Plan"
                    ) {
                      backgroundImage.background = "url('img/gold.png')";
                      buttonBackground.background = "#F6DD61";
                      buttonBackground.color = "#FFFF";
                      const defaultObj =
                        plansArr.filter((p) => p.isDefault)[0] || plansArr[0];
                      //calculate discount price
                      extendPlan = plansArr.filter(
                        (p) =>
                          p.duration ===
                          currentPlan.attributes.DefaultBillingCycle
                      );
                      extendDiscount = extendPlan[0].discountPrice;
                      extendPrice = extendPlan[0].actualPrice;
                      discountPrice =
                        GoldPlanc.discountPrice || defaultObj.discountPrice;
                      //calculate actual price

                      actualPrice =
                        GoldPlanc.actualPrice || defaultObj.actualPrice;

                      //calculate plan amount
                      amount = discountPrice ? discountPrice : actualPrice;
                      //calculate selected billing cycle
                      billingCycle = GoldPlanc.duration;
                      //calculate default billing cycle
                      defualt =
                        currentPlan.attributes.PlansPtr.attributes
                          .DefaultBillingCycle;

                      PlanPrice = Math.floor(currentPlan.attributes.Price);
                      // planprice is assign to the remainingPlanAmount
                      remainingPlanAmount = PlanPrice;
                    }

                    if (
                      currentPlan.attributes.PlansPtr.attributes.PlanName ===
                      "Silver Plan"
                    ) {
                      backgroundImage.background = `url("img/basic.png")`;
                      buttonBackground.background = "#BDC1B9";
                      buttonBackground.color = "#FFFF";
                      const defaultObj =
                        plansArr.filter((p) => p.isDefault)[0] || plansArr[0];
                      discountPrice =
                        SilverPlanc.discountPrice || defaultObj.discountPrice;
                      extendPlan = plansArr.filter(
                        (p) =>
                          p.duration ===
                          currentPlan.attributes.DefaultBillingCycle
                      );
                      extendDiscount = extendPlan[0].discountPrice;
                      extendPrice = extendPlan[0].actualPrice;

                      actualPrice =
                        SilverPlanc.actualPrice || defaultObj.actualPrice;

                      amount = discountPrice ? discountPrice : actualPrice;
                      billingCycle = SilverPlanc.duration;
                      defualt =
                        currentPlan.attributes.PlansPtr.attributes
                          .DefaultBillingCycle;

                      PlanPrice = Math.floor(currentPlan.attributes.Price);
                      remainingPlanAmount = PlanPrice;
                    }

                    if (
                      currentPlan.attributes.PlansPtr.attributes.PlanName ===
                      "Basic Plan"
                    ) {
                      backgroundImage.background = `url("img/premium.png")`;
                      buttonBackground.background = "#EFF1FF";
                      const defaultObj =
                        plansArr.filter((p) => p.isDefault)[0] || plansArr[0];
                      extendPlan = plansArr.filter(
                        (p) =>
                          p.duration ===
                          currentPlan.attributes.DefaultBillingCycle
                      );

                      extendDiscount = extendPlan[0].discountPrice;
                      extendPrice = extendPlan[0].actualPrice;
                      discountPrice =
                        BasicPlanc.discountPrice || defaultObj.discountPrice;
                      actualPrice =
                        BasicPlanc.actualPrice || defaultObj.actualPrice;

                      amount = discountPrice ? discountPrice : actualPrice;
                      billingCycle = BasicPlanc.duration;
                      defualt =
                        currentPlan.attributes.PlansPtr.attributes
                          .DefaultBillingCycle;

                      PlanPrice = Math.floor(currentPlan.attributes.Price);
                      remainingPlanAmount = PlanPrice;
                    }

                    if (
                      currentPlan.attributes.PlansPtr.attributes.PlanName ===
                      "Platinum Plan"
                    ) {
                      backgroundImage.background = `url("img/premium.png")`;
                      buttonBackground.background = "#EFF1FF";
                      const defaultObj =
                        plansArr.filter((p) => p.isDefault)[0] || plansArr[0];
                      extendPlan = plansArr.filter(
                        (p) =>
                          p.duration ===
                          currentPlan.attributes.DefaultBillingCycle
                      );
                      extendDiscount = extendPlan[0].discountPrice;
                      extendPrice = extendPlan[0].actualPrice;

                      discountPrice =
                        PlatinumPlanc.discountPrice || defaultObj.discountPrice;
                      actualPrice =
                        PlatinumPlanc.actualPrice || defaultObj.actualPrice;

                      amount = discountPrice ? discountPrice : actualPrice;
                      billingCycle = PlatinumPlanc.duration;
                      defualt =
                        currentPlan.attributes.PlansPtr.attributes
                          .DefaultBillingCycle;

                      PlanPrice = Math.floor(currentPlan.attributes.Price);
                      remainingPlanAmount = PlanPrice;
                    }
                    const selectYear = billingCycle ? billingCycle : defualt;

                    return (
                      <div key={currentPlan.id} className="col-md-4 uvsborder ">
                        <h3 className="h3 text-uvsh mb-4 ">Current Plan</h3>
                        {/* for extend */}
                        <Modal show={this.state.showPayModal}>
                          <Modal.Header>
                            <Modal.Title>Payment</Modal.Title>

                            <button
                              type="button"
                              className="close "
                              onClick={() => {
                                this.setState({ showPayModal: false });
                              }}
                            >
                              <img src="img/close.png" alt="closeimage" />
                            </button>
                          </Modal.Header>
                          <Modal.Body>
                            <div>
                              <span className="plan-amount">Plan Amount</span>
                              <s className="uvsright">
                                <img src="img/inr.png" alt="price" />
                                {extendPrice}
                              </s>
                            </div>
                            <div>
                              <span className="discount">Discounted Price</span>
                              <span>
                                <strong className="uvsright ">
                                  <img src="img/inr.png" alt="price" />
                                  {extendDiscount}
                                </strong>
                              </span>
                            </div>

                            <div>
                              <span className="plan-amount">Duration</span>
                              <strong className="uvsright ">
                                {currentPlan.attributes.DefaultBillingCycle}
                              </strong>
                            </div>
                          </Modal.Body>

                          <Modal.Footer>
                            <Button
                              variant="primary"
                              onClick={() => {
                                this.setState({ showPayModal: false });

                                this.displayRazorpay(
                                  currentPlan.attributes.PlansPtr,
                                  extendDiscount,
                                  currentPlan.attributes.DefaultBillingCycle,
                                  0,
                                  "extend",
                                  currentPlan
                                );
                              }}
                            >
                              Pay
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        {/* //for re activate */}
                        <Modal show={this.state.reActiveModal}>
                          <Modal.Header>
                            <Modal.Title>Payment</Modal.Title>
                            <button
                              type="button"
                              className="close"
                              onClick={() => {
                                this.setState({ reActiveModal: false });
                              }}
                            >
                              <img src="img/close.png" alt="closeimage" />
                            </button>
                          </Modal.Header>
                          <Modal.Body>
                            <table className="uvsplanss">
                              <tbody>
                                <tr>
                                  <td style={backgroundImage}>
                                    <h3 className="h3 text-uvsh">
                                      <select
                                        onChange={(e) =>
                                          this.onChangePlanPrice(e, plansArr)
                                        }
                                        className=" form-control-uvs d-sm-inline-block shadow"
                                        aria-label="Default select example"
                                        //  value={defualt}
                                        // defaultValue={`${currentPlan.attributes.PlansPtr.attributes.PlanName}c-${d.duration}`}
                                      >
                                        {plansArr.map((d, i) => {
                                          return (
                                            <option
                                              key={`${d.duration}${i}`}
                                              value={`${currentPlan.attributes.PlansPtr.attributes.PlanName}c-${d.duration}`}
                                              selected={
                                                d.duration ===
                                                currentPlan.attributes.PlansPtr
                                                  .attributes
                                                  .DefaultBillingCycle
                                                  ? true
                                                  : false
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {d.duration}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </h3>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span className="plan-amount">
                                      Plan Amount
                                    </span>
                                    <s className="uvsright">
                                      <img src="img/inr.png" alt="price" />
                                      {actualPrice}
                                    </s>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span className="discount">
                                      Discounted Price
                                    </span>
                                    <span>
                                      <strong className="uvsright ">
                                        <img src="img/inr.png" alt="price" />
                                        {discountPrice}
                                      </strong>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="primary"
                              onClick={() => {
                                this.displayRazorpay(
                                  currentPlan.attributes.PlansPtr,
                                  amount,
                                  selectYear,
                                  0
                                );
                              }}
                            >
                              Pay
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        <div
                          className="card shadow mb-4"
                          style={{ padding: 0 }}
                        >
                          <table className="uvsplanss">
                            <tbody>
                              <tr>
                                <td style={backgroundImage}>
                                  <div className="h3 text-uvsh">
                                    {
                                      currentPlan.attributes.PlansPtr.attributes
                                        .PlanName
                                    }
                                    <i
                                      className="fa fa-check-circle right-check"
                                      aria-hidden="true"
                                    ></i>

                                    <div className="expiry-date">
                                      <span>
                                        <strong className="uvsright ">
                                          {!isPlanExpired &&
                                            `Expires ${expiredDate(
                                              currentPlan
                                            )}`}
                                        </strong>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <ul className="uvsplul">
                                    {currentPlan.attributes.PlansPtr.attributes
                                      .PlanDetails &&
                                    currentPlan.attributes.PlansPtr.attributes
                                      .PlanDetails.length > 0
                                      ? currentPlan.attributes.PlansPtr.attributes.PlanDetails.map(
                                          (planAdvantage, key) => {
                                            return (
                                              <li key={key}>
                                                <button className="btn btn-primary btn-circle btn-uvs"></button>
                                                {planAdvantage}
                                              </li>
                                            );
                                          }
                                        )
                                      : resultFound && (
                                          <div>
                                            No result found please check your
                                            filter
                                          </div>
                                        )}
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  <button
                                    className="btn-block btn btnvss  "
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        showModal: true,
                                        modalData: {
                                          name: currentPlan.attributes.PlansPtr
                                            .attributes.PlanName,
                                          features:
                                            currentPlan.attributes.PlansPtr
                                              .attributes.PlanDetails,
                                        },
                                      });
                                    }}
                                  >
                                    More
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {isPlanExpired ? (
                                    <button
                                      disabled={UserRole === "manager"}
                                      className="btn-block btn btnvss btn-warning"
                                      onClick={(e) => {
                                        this.setState({
                                          reActiveModal: true,
                                          currentPtr:
                                            currentPlan.attributes.PlansPtr,
                                        });
                                      }}
                                    >
                                      Re - Activate
                                    </button>
                                  ) : (
                                    <button
                                      disabled={UserRole === "manager"}
                                      className="btn-block btn btnvss btn-warning"
                                      onClick={(e) => {
                                        let _billing = billingCycle
                                          ? billingCycle
                                          : defualt;
                                        this.setState({
                                          showPayModal: true,
                                          currentPtr:
                                            currentPlan.attributes.PlansPtr,
                                          currAmount: amount,
                                          _billing: _billing,

                                          currentPlan: currentPlan,
                                        });
                                      }}
                                    >
                                      {/* {amountPlan ? amountPlan : amount} */}
                                      Extend
                                    </button>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })
                : resultFound && (
                    <div>No result found please check your filter</div>
                  )}

              <div className="col-md-8  ">
                <h3 id="subs-height" className="h3 text-uvsh mb-4 subheight ">
                  Subscription Plans
                </h3>

                <div className="row uvsappss">
                  {loading ? (
                    <div style={{ margin: "0 auto" }}>
                      <Loading />
                    </div>
                  ) : availablePlans && availablePlans.length > 0 ? (
                    availablePlans.map((subscrib, key) => {
                      let plansArr = [];
                      for (let i in subscrib.attributes) {
                        let obj = {};
                        switch (i) {
                          case "Price1Mo":
                            obj["duration"] = "1 Month";
                            obj["actualPrice"] = subscrib.attributes[i];
                            obj["discountPrice"] = subscrib.get("Mrp1Mo");
                            obj["features"] = [];
                            obj["isDefault"] =
                              subscrib.get("DefaultBillingCycle") === "1 Month";
                            plansArr.push(obj);
                            break;
                          case "Price3Mo":
                            obj["duration"] = "3 Months";
                            obj["actualPrice"] = subscrib.attributes[i];
                            obj["discountPrice"] = subscrib.get("Mrp3Mo");
                            obj["features"] = [];
                            obj["isDefault"] =
                              subscrib.get("DefaultBillingCycle") ===
                              "3 Months";

                            plansArr.push(obj);
                            break;
                          case "Price6Mo":
                            obj["duration"] = "6 Months";
                            obj["actualPrice"] = subscrib.attributes[i];
                            obj["discountPrice"] = subscrib.get("Mrp6Mo");
                            obj["features"] = [];
                            obj["isDefault"] =
                              subscrib.get("DefaultBillingCycle") ===
                              "6 Months";
                            plansArr.push(obj);
                            break;
                          case "Price12Mo":
                            obj["duration"] = "1 Year";
                            obj["actualPrice"] = subscrib.attributes[i];
                            obj["discountPrice"] = subscrib.get("Mrp12Mo");
                            obj["features"] = [];
                            obj["isDefault"] =
                              subscrib.get("DefaultBillingCycle") === "1 Year";

                            plansArr.push(obj);
                            break;
                          case "Price36Mo":
                            obj["duration"] = "3 Years";
                            obj["actualPrice"] = subscrib.attributes[i];
                            obj["discountPrice"] = subscrib.get("Mrp36Mo");
                            obj["features"] = [];
                            obj["isDefault"] =
                              subscrib.get("DefaultBillingCycle") === "3 Years";

                            plansArr.push(obj);
                            break;
                          case "Price60Mo":
                            obj["duration"] = "5 Years";
                            obj["actualPrice"] = subscrib.attributes[i];
                            obj["discountPrice"] = subscrib.get("Mrp60Mo");
                            obj["features"] = [];
                            obj["isDefault"] =
                              subscrib.get("DefaultBillingCycle") === "5 Years";

                            plansArr.push(obj);
                            break;
                          default:
                            break;
                        }
                      }
                      let backgroundImage = {
                        background: "",
                        backgroundSize: "100% 100%",
                        padding: "20px",
                      };
                      const { BasicPlan, SilverPlan, GoldPlan, PlatinumPlan } =
                        this.state;

                      let discountPrice,
                        actualPrice,
                        amount,
                        billingCycle,
                        defaultPrice,
                        defaultDiscount,
                        defualt;

                      let buttonBackground = {
                        background: "",
                        color: "",
                      };

                      if (subscrib.get("PlanName") === "Gold Plan") {
                        backgroundImage.background = "url('img/gold.png')";
                        buttonBackground.background = "#F6DD61";
                        buttonBackground.color = "#FFFF";
                        const defaultObj =
                          plansArr.filter((p) => p.isDefault)[0] || plansArr[0];

                        discountPrice =
                          GoldPlan.discountPrice || defaultObj.discountPrice;
                        actualPrice =
                          GoldPlan.actualPrice || defaultObj.actualPrice;
                        defaultPrice = defaultObj.actualPrice;
                        defaultDiscount = defaultObj.discountPrice;
                        amount = discountPrice ? discountPrice : actualPrice;
                        billingCycle = GoldPlan.duration;
                        defualt = subscrib.get("DefaultBillingCycle");
                      }
                      if (subscrib.get("PlanName") === "Silver Plan") {
                        backgroundImage.background = `url("img/basic.png")`;
                        buttonBackground.background = "#BDC1B9";
                        buttonBackground.color = "#FFFF";

                        const defaultObj =
                          plansArr.filter((p) => p.isDefault)[0] || plansArr[0];
                        discountPrice =
                          SilverPlan.discountPrice || defaultObj.discountPrice;
                        actualPrice =
                          SilverPlan.actualPrice || defaultObj.actualPrice;
                        defaultPrice = defaultObj.actualPrice;
                        defaultDiscount = defaultObj.discountPrice;
                        amount = discountPrice ? discountPrice : actualPrice;
                        billingCycle = SilverPlan.duration;
                        defualt = subscrib.get("DefaultBillingCycle");
                      }

                      if (subscrib.get("PlanName") === "Basic Plan") {
                        backgroundImage.background = `url("img/premium.png")`;
                        buttonBackground.background = "#EFF1FF";
                        const defaultObj =
                          plansArr.filter((p) => p.isDefault)[0] || plansArr[0];
                        discountPrice =
                          BasicPlan.discountPrice || defaultObj.discountPrice;
                        actualPrice =
                          BasicPlan.actualPrice || defaultObj.actualPrice;
                        defaultPrice = defaultObj.actualPrice;
                        defaultDiscount = defaultObj.discountPrice;
                        amount = discountPrice ? discountPrice : actualPrice;
                        billingCycle = BasicPlan.duration;
                        defualt = subscrib.get("DefaultBillingCycle");
                      }
                      if (subscrib.get("PlanName") === "Platinum Plan") {
                        backgroundImage.background = `url("img/premium.png")`;
                        buttonBackground.background = "#EFF1FF";

                        const defaultObj =
                          plansArr.filter((p) => p.isDefault)[0] || plansArr[0];

                        discountPrice =
                          PlatinumPlan.discountPrice ||
                          defaultObj.discountPrice;
                        actualPrice =
                          PlatinumPlan.actualPrice || defaultObj.actualPrice;
                        defaultPrice = defaultObj.actualPrice;
                        defaultDiscount = defaultObj.discountPrice;
                        amount = discountPrice ? discountPrice : actualPrice;
                        billingCycle = PlatinumPlan.duration;
                        defualt = subscrib.get("DefaultBillingCycle");
                      }

                      const subscribeModalFun = (
                        ind,
                        discountPrice,
                        actualPrice,
                        defualtDay
                      ) => {
                        const biilingSub = billingCycle
                          ? billingCycle
                          : defualt;
                        return (
                          <Modal show={this.state.subscribeModal}>
                            <Modal.Header>
                              <Modal.Title>Payment</Modal.Title>
                              <button
                                type="button"
                                className="close "
                                onClick={() => {
                                  actualPrice = defaultPrice;
                                  discountPrice = defaultDiscount;

                                  this.setState({
                                    subscribeModal: false,
                                    BasicPlan: {},
                                    SilverPlan: {},
                                    GoldPlan: {},
                                    PlatinumPlan: {},
                                  });
                                }}
                              >
                                <img src="img/close.png" alt="closeimage" />
                              </button>
                            </Modal.Header>
                            <Modal.Body>
                              <table className="uvsplanss">
                                <tbody>
                                  <tr>
                                    <td style={backgroundImage}>
                                      <h3 className="h3 text-uvsh">
                                        <select
                                          onChange={(e) =>
                                            this.onChangePlanPrice(e, plansArr)
                                          }
                                          className=" form-control-uvs d-sm-inline-block shadow"
                                          aria-label="Default select example"
                                          //  value={defualt}
                                          defaultValue={`${subscrib.get(
                                            "PlanName"
                                          )}-${subscrib.get(
                                            "DefaultBillingCycle"
                                          )}`}
                                        >
                                          {plansArr.map((d, i) => {
                                            return (
                                              <option
                                                key={`${d.duration}${i}`}
                                                value={`${subscrib.get(
                                                  "PlanName"
                                                )}-${d.duration}`}
                                                selected={
                                                  d.duration === defualtDay &&
                                                  true
                                                }
                                              >
                                                {/* {defualtDay} */}
                                                {d.duration}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </h3>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span className="plan-amount">
                                        Plan Amount
                                      </span>
                                      <s className="uvsright">
                                        <img src="img/inr.png" alt="price" />
                                        {actualPrice}
                                      </s>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span className="discount">
                                        Discounted Price
                                      </span>
                                      <span>
                                        <strong className="uvsright ">
                                          <img src="img/inr.png" alt="price" />
                                          {discountPrice}
                                        </strong>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Modal.Body>

                            <Modal.Footer>
                              <Button
                                variant="primary"
                                onClick={() => {
                                  this.displayRazorpay(
                                    subscrib,
                                    amount,
                                    biilingSub,
                                    remainingPlanAmount,
                                    "switch"
                                  );
                                }}
                              >
                                Pay
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        );
                      };

                      return (
                        <div key={subscrib.id} className="col-md-6">
                          <div className="card mb-4" style={{ padding: 0 }}>
                            {subscribeModal &&
                              key === modalKey &&
                              subscribeModalFun(
                                key,
                                discountPrice,
                                actualPrice,
                                defualt
                                // subscrib.get("DefaultBillingCycle")
                              )}
                            <table className="uvsplanss">
                              <tbody>
                                <tr>
                                  <td style={backgroundImage}>
                                    <h3 className="h3 text-uvsh">
                                      {subscrib.get("PlanName")}
                                    </h3>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <ul className="uvsplul">
                                      {subscrib.get("PlanDetails") &&
                                      subscrib.get("PlanDetails").length > 0
                                        ? subscrib
                                            .get("PlanDetails")
                                            .map((planAdvantage, key) => {
                                              return (
                                                <li key={key}>
                                                  <button className="btn btn-primary btn-circle btn-uvs"></button>
                                                  {planAdvantage}
                                                </li>
                                              );
                                            })
                                        : resultFound && (
                                            <div>
                                              No result found please check your
                                              filter
                                            </div>
                                          )}
                                    </ul>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center">
                                    <button
                                      className="btn-block btn btnvss  "
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          showModal: true,
                                          modalData: {
                                            name: subscrib.attributes.PlanName,
                                            features:
                                              subscrib.attributes.PlanDetails,
                                          },
                                        });
                                      }}
                                    >
                                      More
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="box">
                                    <button
                                      // style={buttonBackground}
                                      className="btn-block btn btnvss btn-warning"
                                      required
                                      disabled={UserRole === "manager"}
                                      onClick={(e) => {
                                        this.setState({
                                          modalKey: key,
                                          subscribeModal: true,
                                          selectedPlanName:
                                            subscrib.attributes.PlanName,
                                        });
                                      }}
                                    >
                                      Buy Now
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    resultFound && (
                      <div>No result found please check your filter</div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <PlanAdvantageModal data={modalData} />
      </React.Fragment>
    );
  }
}
