import React, { Component } from "react";
import MaterialTable from "@material-table/core";
import { getCurrentTenant } from "../utils/util";
import { Modal, Button } from "react-bootstrap";
import Loading from "./Loading";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import axios from "axios";
import Parse from "parse";
import { getHasplanPurchase } from "../utils/util";

import { saveCreditsTransaction } from "../utils/util";
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
export class Notification extends Component {
  constructor() {
    super();
    this.state = this.initialState;
  }
  initialState = {
    notificationData: [],
    loading: true,
    showModal: false,
    selectId: null,
    selectCredits: null,
    inputCredits: null,
    notifyCredits: [],
    paymentLoading: false, //this is boolean type state.paymentLoading initially false.
    notificationPlan: ["Email", "SMS", "Whatsapp", "Storage"],
    selectedNotificatonType: "",

    perCountData: ["0.1/Email", "0.15/SMS", "0.5/Whatsapp", "100/GB"],
    totalAmount: 0,
  };

  //for getting notification count data
  getNotificationCount = async () => {
    try {
      const tenantResult = getCurrentTenant();

      const adminID = tenantResult.UserId.objectId;
      const orgQuery = new Parse.Query("orgApps");

      orgQuery.equalTo("superAdmin", {
        __type: "Pointer",
        className: "_User",
        objectId: adminID,
      });
      const res = await orgQuery.first();
      if (res) {
        const result = JSON.parse(JSON.stringify(res));
        console.log("result", result, result.appId, result.publicServerURL);
        const parseAppId = result.appId;
        const serverUrl = result.publicServerURL;
        const response = await axios
          .get(`${serverUrl}/classes/partners_TenantCredits`, {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": parseAppId,
            },
          })
          .then((result) => {
            const res = result.data;
            //  console.log("res", res);
            if (res.results[0]) {
              this.setState({ notifyCredits: [res.results[0]], loading: false });
            }
            return res.results[0];
          })
          .catch((err) => {
            console.log("axois err ", err);
          });

         
          
        console.log("response", response, response[0].objectId);
      }
    } catch (e) {
      console.log("org app error", e);
    }
  };

  componentDidMount() {
    //Runs after the first render() lifecycle
    const isPlanPerchased = getHasplanPurchase();
    if (isPlanPerchased) {
      this.getNotificationCount();
    } else {
      this.setState({ loading: false });
    }
  }
  //get notification type
  onChangeNotificationPlan = (e) => {
    // console.log("select value", e, e.target.value);
    this.setState({ selectedNotificatonType: e.target.value });
    if (this.state.selectId || this.state.inputCredits) {
      this.setState({ selectId: "", totalAmount: 0, inputCredits: "" });
    }
  };

  myFunction = (msg) => {
    var x = document.getElementById("snackbar");
    x.className = "show";
    x.innerHTML = msg;
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  render() {
    const {
      inputCredits,
      loading,
      selectId,
      notifyCredits,
      selectCredits,
      paymentLoading,
      notificationPlan,
      selectedNotificatonType,
      showModal,
      perCountData,
      totalAmount,
    } = this.state;

    //render notification data in table
    const data = notifyCredits.map((notify, ind) => {
      return {
        smsCredits: notify.sms ? <span>{notify.sms}</span> : <span>0</span>,

        emailCredits: notify.email ? (
          <span>{notify.email}</span>
        ) : (
          <span>0</span>
        ),
        whatsappCredits: notify.whatsapp ? (
          <span>{notify.whatsapp}</span>
        ) : (
          <span>0</span>
        ),
        usedStorage: notify.usedStorage ? (
          usedStorage(notify.usedStorage,notify.allowedStorage)
        ) : (
          // <span>{notify.usedStorage}</span>
          <span>0</span>
        ),
       
        buy: (
          <button
            onClick={() =>
              this.setState({
                showModal: true,
              })
            }
            className="btn btn-primary btn-sm"
          >
            Buy Now
          </button>
        ),
      };
    });

    function usedStorage(storage,allowedStorage) {

      const inMb = (storage * 0.000001).toFixed(2);
      let inGb;
      if (inMb >= 1024) {
        inGb =  (inMb / 1024).toFixed(2);

      }
      return inGb ? <span>{inGb} GB of {allowedStorage}GB Used</span> : <span> {inMb} MB of {allowedStorage}GB Used</span>;
    }
    //get on select chip money
    const chipFilter = (item, id) => {
      // console.log("item", item);
      let credits = item;
      this.setState({ selectCredits: credits });
      if (inputCredits) {
        this.setState({ inputCredits: "" });
      }
      if (id === selectId) {
        this.setState({ selectId: "" });
        credits = "";
        this.setState({ selectCredits: credits });
      } else {
        this.setState({ selectId: id });

        calculateMOney(selectedNotificatonType, credits);
      }
    };

    const calculateMOney = (selectedNotificatonType, credits) => {
      let perCount, amount;
      if (selectedNotificatonType === "Email") {
        perCount = 0.1;
        amount = Math.floor(credits * perCount);
        this.setState({ totalAmount: amount });
      } else if (selectedNotificatonType === "SMS") {
        perCount = 0.15;
        amount = Math.floor(credits * perCount);
        this.setState({ totalAmount: amount });
      } else if (selectedNotificatonType === "Whatsapp") {
        perCount = 0.5;
        amount = Math.floor(credits * perCount);
        this.setState({ totalAmount: amount });
      } else if (selectedNotificatonType === "Storage") {
        perCount = 100;
        amount = Math.floor(credits * perCount);
        this.setState({ totalAmount: amount });
      } else if (!selectedNotificatonType) {
        perCount = 0.1;
        amount = Math.floor(credits * perCount);
        this.setState({ totalAmount: amount });
      }
    };
    //  console.log("select money", selectCredits);
    //get input money data and save it
    const onChangeValue = (event) => {
      const credits = event.target.value;

      if (selectId) {
        this.setState({ selectId: "" });
      }
      this.setState({ selectCredits: credits, inputCredits: credits });
      calculateMOney(selectedNotificatonType, credits);
    };

    //payment function
    const displayRazorpay = async () => {
      // console.log("select crdits", selectCredits);
      if (selectCredits) {
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
          this.setState({ paymentLoading: false });
          alert("Razorpay SDK failed to load. Are you online?");
          return;
        }
        //This cloud function is use to create order and return order details
        const order = await Parse.Cloud.run("CreateOrder", {
          amount: totalAmount,
          currency: "INR",
          env: "dev", //__DEV__ ? "dev" : "prod",
        });
        console.log("order", order, res);
        const options = {
          key: "rzp_test_JuKUtrnG9SoyHk", // __DEV__ ? "rzp_test_JuKUtrnG9SoyHk" : "rzp_live_wtUytXxTyUkjds",
          currency: order.currency,
          amount: order.amount,
          order_id: order.id,
          name: "Qik.Ai",
          // image: "http://localhost:3000/logo.svg",
          //function for success payment
          //handler is a callback function .it execute last
          handler: async (response) => {
            //If the payment is successful then its return the following fields.
            const {
              razorpay_signature,
              razorpay_payment_id,
              razorpay_order_id,
            } = response;
            //this is payment capture api run here
            //capture payment either success or failed

            const captureResponse = await Parse.Cloud.run("paymentCapture", {
              razorpay_payment_id,
              razorpay_signature,
              razorpay_order_id,
              totalAmount,
              currency: "INR",
              env: "dev", //__DEV__ ? "dev" : "prod",
            });
            //function for save successful transaction
            const { items } = captureResponse;
            const amount = totalAmount.toString();
            if (items.length > 0) {
              const result = await saveCreditsTransaction(
                items, //payment details

                amount, //selected plan amount
                razorpay_payment_id, //
                razorpay_order_id //
              );
              if (result) {
                // alert("Transaction done successfully. ");
                console.log("result", result);
                // this.myFunction("Transaction done successfully.");

                let data, totalCount;
                const creditsCount = parseInt(selectCredits);
                if (selectedNotificatonType) {
                  if (selectedNotificatonType === "Email") {
                    const emailCount = notifyCredits[0].email;

                    totalCount = creditsCount + emailCount;
                    data = {
                      email: totalCount,
                    };
                  } else if (selectedNotificatonType === "SMS") {
                    const smsCount = notifyCredits[0].sms;
                    totalCount = creditsCount + smsCount;
                    data = {
                      sms: totalCount,
                    };
                  } else if (selectedNotificatonType === "Whatsapp") {
                    let whatsappCount = 0;
                    if (notifyCredits[0].whatsapp) {
                      whatsappCount = notifyCredits[0].whatsapp;
                    }
                    totalCount = creditsCount + whatsappCount;
                    data = {
                      whatsapp: totalCount,
                    };
                  } else if (selectedNotificatonType === "Storage") {
                    console.log("storage entry")
                    let storage = 0;
                    if (notifyCredits[0].allowedStorage) {
                      storage = notifyCredits[0].allowedStorage;
                    }
                    totalCount = creditsCount + storage;
                   console.log("total count",totalCount)
                    data = {
                      allowedStorage: totalCount,
                    };
                  } 
                } else {
                  data = {
                    email: creditsCount + notifyCredits[0].email,
                  };
                }
                console.log(
                  "data update",
                  data,
                  creditsCount,
                  selectedNotificatonType
                );
                //for migrate admin data from base db to client db
                try {
                  const tenantResult = getCurrentTenant();

                  const adminID = tenantResult.UserId.objectId;
                  const orgQuery = new Parse.Query("orgApps");

                  orgQuery.equalTo("superAdmin", {
                    __type: "Pointer",
                    className: "_User",
                    objectId: adminID,
                  });
                  const res = await orgQuery.first();
                  const result = JSON.parse(JSON.stringify(res));

                  const parseAppId = result.appId;
                  const serverUrl = result.publicServerURL;
                  await axios
                    .put(
                      `${serverUrl}/classes/partners_TenantCredits/${notifyCredits[0].objectId}`,
                      data,
                      {
                        headers: {
                          "Content-Type": "application/json",
                          "X-Parse-Application-Id": parseAppId,
                        },
                      }
                    )
                    .then((result) => {
                      const res = result.data;
                      console.log("save res", res);
                      this.setState({ showModal: false,
                        selectCredits: null,
                        selectId: null,
                        inputCredits: null,})
                    })
                    .catch((err) => {
                      console.log("axois err ", err);
                    });
                } catch (e) {
                  console.log("org app error", e);
                }
                this.setState({   loading: true });
                this.getNotificationCount();
              }
            }
          },
          //function for save  failure transactions on modal dismiss.
          // modal: {
          //   escape: false,
          //   ondismiss: async () => {
          //     const captureResponse = await Parse.Cloud.run("paymentCapture", {
          //       razorpay_payment_id: null,
          //       razorpay_signature: null,
          //       razorpay_order_id: order.id,
          //       amount: remainingPlanAmount ? amount - remainingPlanAmount : amount,
          //       currency: "INR",
          //       env: "dev", // __DEV__ ? "dev" : "prod",
          //     });
          //     const { items } = captureResponse;
          //     if (items.length > 0) {
          //       const result = await saveTransaction(
          //         items, //its contains payment details
          //         _billing, //selected billing cycle
          //         amount, //selected plan amount
          //         selectedPlan, //selected plan name
          //         null, //payment id is null because its capture failed transaction
          //         null, //order id is null because its capture failed transaction
          //         daysLeft //its contains existing duration(daysleft)
          //       );
          //       if (result) {
          //         // alert("Transaction failed. ");
          //         this.myFunction("Transaction failed. ");
          //         currentsubscriptionsPlansDetails(); //refetch current subscriptions details
          //         subscriptionsDetails(); //refetch subscriptions details
          //       }
          //     }
          //   },
          //   onclose: async () => {}, //this is method of close module
          // },
          // prefill: {
          //   //Cardholders name to be pre-filled when the Checkout opens.
          //   name: curretTenant.TenantName,
          //   email: curretTenant.EmailAddress,
          //   contact: curretTenant.ContactNumber,
          // },
        };

        //show iframe overlay in modal format
        this.setState({ paymentLoading: false }); //after payment capture loading false
        const paymentObject = new window.Razorpay(options); //its take options as a parameter.
        paymentObject.open(); //ake options and open modal
      } else {
        alert("Please enter credits to buy!");
      }
    };

    const option = {
      toolbar: false,
      showTitle: false,
      search: false,
      paging: false,
      headerStyle: {
        border: "1px solid #e5e5e5",
        width: "auto",
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        backgroundColor: "#edebeb",
        padding:8
      },
      rowStyle: {
        alignItem: "center",
        textAlign: "center",
        color: "black",
        borderRight: "1px solid #e5e5e5",
      },
    };
    const TableCellStyle = { borderRight: "1px solid #e5e5e5" };
    const columns = [
      {
        field: "smsCredits",
        title: "SMS Credits",
        cellStyle: TableCellStyle,
        // footer: "",
      },
      {
        field: "emailCredits",
        title: "Email Credits",
        cellStyle: TableCellStyle,
      },
      {
        field: "whatsappCredits",
        title: "Whatsapp Credits",
        cellStyle: TableCellStyle,
      },
      {
        field: "usedStorage",
        title: "Storage",
        cellStyle: TableCellStyle,
      },
     
      {
        field: "buy",
        title: "",
        cellStyle: TableCellStyle,
      },
    ];

    return (
      <div className="container">
        {paymentLoading && (
          <div
            className="payment-loader"
            style={{
              margin: "0 auto",
              position: "absolute",
              height: "100vh",
              width: "100%",
            }}
          >
            <Loading type="spin" />
          </div>
        )}
        {loading ? (
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        ) : (
          <MaterialTable
            columns={columns}
            data={data}
            options={option}
            // onRowsPerPageChange={1}
          />
        )}
        <Modal show={showModal}>
          <Modal.Header>
            <Modal.Title>Select Credits to Buy</Modal.Title>

            <button
              type="button"
              className="close "
              onClick={() => {
                this.setState({
                  showModal: false,
                  selectCredits: null,
                  selectId: null,
                  inputCredits: null,
                });
              }}
            >
              <img src="img/close.png" alt="closeimage" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <h3 className="h3 text-uvsh">
              <select
                onChange={(e) => this.onChangeNotificationPlan(e)}
                className=" form-control-uvs d-sm-inline-block shadow"
                aria-label="Default select example"
                //  value={defualt}
                defaultValue="Email"
              >
                {notificationPlan.map((d, i) => {
                  return (
                    <option key={`${d}${i}`} value={d} selected="Email">
                      {d}
                    </option>
                  );
                })}
              </select>
            </h3>

            <p style={{ fontWeight: "bold" }}>
              Price - Rs.
              {perCountData.map((data, key) => {
                // console.log("notification", selectedNotificatonType, key);
                return (
                  <span key={key}>
                    {(!selectedNotificatonType && key === 0) ||
                    (selectedNotificatonType === "Email" && key === 0) ? (
                      <span>{data}</span>
                    ) : selectedNotificatonType === "SMS" && key === 1 ? (
                      <span>{data}</span>
                    ) : selectedNotificatonType === "Whatsapp" ? (
                      key === 2 && <span>{data}</span>
                    ) : (
                      selectedNotificatonType === "Storage" &&
                      key === 3 && <span>{data}</span>
                    )}
                  </span>
                );
              })}
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginTop: 10 }}>
                <Stack direction="row" spacing={1}>
                  <Chip
                    label="1000"
                    variant={selectId === "0" ? "filled" : "outlined"}
                    clickable={true}
                    color="success"
                    onClick={() => chipFilter("1000", "0")}
                  />
                  <Chip
                    label="5000"
                    clickable={true}
                    variant={selectId === "1" ? "filled" : "outlined"}
                    color="success"
                    onClick={() => chipFilter("5000", "1")}
                  />
                  <Chip
                    label="10000"
                    variant={selectId === "2" ? "filled" : "outlined"}
                    clickable={true}
                    color="success"
                    onClick={() => chipFilter("10000", "2")}
                  />
                </Stack>
              </div>
              <p
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  margin: 10,
                  fontWeight: "bold",
                }}
              >
                Or
              </p>
              <input
                type="number"
                id="money"
                name="money"
                value={inputCredits}
                placeholder="Enter credits to buy"
                className="form-control  input-sm"
                style={{ fontSize: 15 }}
                onChange={onChangeValue}
              />
            </div>
            <p style={{ color: "black", fontWeight: "500" }}>
              Amount to pay - Rs. {selectId || selectCredits ? totalAmount : 0}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="btn-sm"
              variant="primary"
              onClick={() => displayRazorpay()}
            >
              Buy Now
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default Notification;
