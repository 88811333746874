import React, { Component } from "react";
import { logoutUser, getCurrentTenant } from "../utils/util";
import { Link } from "react-router-dom";
import "./Nav.css";
import Parse from "parse";
import { Modal } from "react-bootstrap";

export class Nav extends Component {
  constructor() {
    super();
    this.state = this.initialState;
  }
  initialState = {
    userName: "",
    imageUrl: "",
    subUserName: "",
    UserRole: ["admin", "manager", "accounts"],
    showModal: false,
  };
  currentUserDetails = async () => {
    const subUser = Parse.Object.extend("partners_SubUsers"),
      querysubUser = new Parse.Query(subUser),
      user = Parse.User.current();
    querysubUser.include("TenantId");
    querysubUser.equalTo("UserId", user);
    const subUserResult = await querysubUser.first();
    const tenantResult = getCurrentTenant();
    if (user && (tenantResult || subUserResult)) {
      this.setState({
        userName: subUserResult
          ? subUserResult.attributes.UserId.attributes.name
          : tenantResult.CreatedBy.name || tenantResult.CreatedBy.name,
        imageUrl: JSON.parse(localStorage.getItem("userPic")).src,
      });
    }
  };
  componentDidMount() {
    this.currentUserDetails();
  }
  render() {
    const { userName, imageUrl } = this.state;
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand navbar-light topbar static-top">
          <button
            onClick={(e) => e.preventDefault()}
            id="sidebarCollapse"
            style={{ marginLeft: 15 }}
          >
            <i className="fa fa-bars" style={{ color: "#0d6efd" }}></i>
          </button>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown no-arrow">
              <div className="dropdown">
                <button
                  className="nav-link dropdown-toggle"
                  style={{
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                  }}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="username">
                    {imageUrl ? (
                      <img
                        className="img-profile rounded-circle"
                        src={imageUrl}
                        alt="img"
                      />
                    ) : (
                      <i
                        className="fa fa-user fa-sm fa-fw mr-2 text-gray-400"
                        style={{ fontSize: 20 }}
                      />
                    )}
                    <span
                      className="mr-2  d-lg-inline text-gray-600 small "
                      style={{ marginLeft: "10px", fontWeight: "bold" }}
                    >
                      {userName}
                    </span>
                  </div>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/UserProfile">
                      <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/Settings">
                      <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                      Settings
                    </Link>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ showModal: true });
                      }}
                      data-toggle="modal"
                      data-target="#logoutModal"
                      data-backdrop="false"
                    >
                      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
        {this.state.showModal ? (
          <Modal show={true}>
            <Modal.Header>
              <Modal.Title>
                {" "}
                <i className="fa fa-sign-out" aria-hidden="true"></i>Log Out
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <i className="fa fa-question-circle"></i> Are you sure you want
                to log-off? <br />
              </p>
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  this.setState({ showModal: false });
                }}
              >
                Close
              </button>
              <button
                onClick={logoutUser}
                type="button"
                className="btn btn-primary"
              >
                Log Out
              </button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}
