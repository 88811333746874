import React from "react";
import { Redirect, Route } from "react-router-dom";
import App from "./App";
import { isAuth } from "./utils/util";
//Operation 1: Find the component property defined on props (Note: lowercase component) and assign it to a new location
// in state we call Component (Note: capital Component).
//Operation 2: Then, take all remaining properties defined on the props object and collect them inside an argument called rest.
//The important point is that you're NOT renaming props to rest.
//rest === props;
// => false

// => { age: 35, sex: 'M', dob: Mon Jan 01 1990 00:00:00 GMT-0800 (PST) }
//simply rest means remaining
const DashboardRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest} //
      render={(props) =>
        isAuth() ? (
          <App>
            <Component {...props} />
          </App>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }, // from is a state for Redirect component
            }}
          />
        )
      }
    />
  );
};

export default DashboardRoutes;
