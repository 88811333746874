import React, { Component } from "react";
import { Link } from "react-router-dom";
export class Terms extends Component {
  render() {
    return (
      <main className="wrap">
        <section className="container">
          <div className="container__heading">
            <h2>Terms, Policy and Conditions</h2>
          </div>
          <div className="container__content">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.
              Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.
              Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris
              massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti
              sociosqu ad litora torquent per conubia nostra, per inceptos
              himenaeos. Curabitur sodales ligula in libero. Sed dignissim
              lacinia nunc.{" "}
            </p>
            <p>
              Curabitur tortor. Pellentesque nibh. Aenean quam. In scelerisque
              sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin
              ut ligula vel nunc egestas porttitor. Morbi lectus risus, iaculis
              vel, suscipit quis, luctus non, massa. Fusce ac turpis quis ligula
              lacinia aliquet. Mauris ipsum. Nulla metus metus, ullamcorper vel,
              tincidunt sed, euismod in, nibh. Quisque volutpat condimentum
              velit.{" "}
            </p>
            <p>
              Class aptent taciti sociosqu ad litora torquent per conubia
              nostra, per inceptos himenaeos. Nam nec ante. Sed lacinia, urna
              non tincidunt mattis, tortor neque adipiscing diam, a cursus ipsum
              ante quis turpis. Nulla facilisi. Ut fringilla. Suspendisse
              potenti. Nunc feugiat mi a tellus consequat imperdiet. Vestibulum
              sapien. Proin quam. Etiam ultrices. Suspendisse in justo eu magna
              luctus suscipit.{" "}
            </p>
            <p>
              Sed lectus. Integer euismod lacus luctus magna. Quisque cursus,
              metus vitae pharetra auctor, sem massa mattis sem, at interdum
              magna augue eget diam. Vestibulum ante ipsum primis in faucibus
              orci luctus et ultrices posuere cubilia Curae; Morbi lacinia
              molestie dui. Praesent blandit dolor. Sed non quam. In vel mi sit
              amet augue congue elementum. Morbi in ipsum sit amet pede
              facilisis laoreet. Donec lacus nunc, viverra nec, blandit vel,
              egestas et, augue. Vestibulum tincidunt malesuada tellus. Ut
              ultrices ultrices enim. Curabitur sit amet mauris.{" "}
            </p>
            <p>
              Morbi in dui quis est pulvinar ullamcorper. Nulla facilisi.
              Integer lacinia sollicitudin massa. Cras metus. Sed aliquet risus
              a tortor. Integer id quam. Morbi mi. Quisque nisl felis, venenatis
              tristique, dignissim in, ultrices sit amet, augue. Proin sodales
              libero eget ante. Nulla quam. Aenean laoreet.{" "}
            </p>
          </div>
          <div className="container__nav">
            <small>
              By clicking 'Accept' you are agreeing to our terms and conditions.
            </small>
            <Link className="button" to="/Signup">
              Accept
            </Link>
          </div>
        </section>
      </main>
    );
  }
}
